import React, { Component } from "react";
import ReactLoading from "react-loading";
import Header from "./components/Header";
import Buy from "./Buy";
import { postRequest } from "../../../utils/httpRequest";
import { validateString, validateTotalAmount, calcfixedPrice } from "./helpers";
import "./index.scss";
import CheckBrokerStatus from "./components/CheckBrokersStatus";
import CreateOfferModal from "./components/CreateofferModal";
import bcloader from "../../../../assets/images/bcloader.gif";
import { error } from "console";

class New extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tradeType: props.tradeType,
      step: "selectTrade",
      token: "USDT",
      selectedCurrency: {
        label: props.currentUserAltCurrency,
        value: props.currentUserAltCurrency,
        rateToUsd: props.currentUserAltCurrencyRate,
        overrideRate: null,
        sellRate: null,
        buyRate: null,
      },
      selectedCountry: {
        label: props.currentUserCountry,
        value: props.currentUserCountryCode,
      },
      selectedPaymentMethod: { label: "Bank Transfer", value: "bank-transfer" },
      selectedOnlineWallet: null,
      selectedPaymentMethodGroup: "bank-transfers",
      selectedPaymentMethodId: props.bankTransferPaymentMethodId,
      selectedBankAccount: {},
      margin: 5,
      rangeMin: null,
      rangeMax: null,
      paymentWindow: 8,
      offerTerms: "",
      tradeDetails: "",
      tags: [{ label: "no third parties", value: "no-third-parties" }],
      fixedPrice: 0.0,
      amount: 0.0,
      tradeFees: props.tradeFees,
      isFixedPrice: false,
      requireVerifiedId: false,
      requireMinPastTrades: 0,
      showOnlyTrustedUser: false,
      requireTraderName: false,
      countryLimitationType: "none",
      countryLimitationList: null,
      hasError: false,
      validationMessage: "",
      customOfferLabel: props.currentUserName,
      dutyHours: [],
      availableBalance: parseFloat(props.availableBalance).toFixed(2),
      showModal: false,
      errorModal: false,
      isProfanityDetected: false,
      amountValError: 0,
    };
    this.toggleModal = this.toggleModal.bind(this);
  }

  componentDidMount() {}

  updateState = (key, value) => {
    this.setState({ [key]: value });
  };

  getState = (key) => {
    return this.state[key];
  };

  updatePreviousStep = (e) => {
    e.preventDefault();

    const { step } = this.state;

    switch (step) {
      case "settings":
        this.setState({ step: "pricing" });

      case "pricing":
        this.setState({ step: "selectTrade" });
    }
  };

  scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });    
  }
 
  updateNextStep = (e, step) => {
    const { amount, availableBalance, tradeType, tradeFees } = this.state;
    e.preventDefault();
    let isError = false;

    if (step === "pricing") {
      if (!this.state.selectedPaymentMethod) {
        this.updateState("validationMessage", "Payment method is required");
        this.updateState("hasError", true);
        isError = true;
        this.scrollToTop();       
      }

      if (this.state.selectedPaymentMethod.value === "bank-transfer" && !this.state.selectedBankAccount.label) {
        this.updateState("validationMessage", "Please Select Your Bank Account");
        this.updateState("hasError", true);
        isError = true;
        this.scrollToTop();
      }

      if (this.state.selectedPaymentMethodGroup === "online-transfers" && this.state.selectedPaymentMethod.value !== "airline-tickets" && !this.state.selectedOnlineWallet) {
        this.updateState("validationMessage", "Please Select Your Online Wallet");
        this.updateState("hasError", true);
        isError = true;
        this.scrollToTop();
      }

      if (!this.state.selectedCurrency) {
        this.updateState(
          "validationMessage",
          "Please Select Preferred Currency"
        );
        this.updateState("hasError", true);
        isError = true;
        this.scrollToTop();
      }
    } 
    else if (step === "settings") {
      if (!this.state.rangeMin) {
        this.updateState(
          "validationMessage",
          "Please enter min offer trade limit"
        );
        this.updateState("hasError", true);
        isError = true;
        this.scrollToTop();
      } else if (!this.state.rangeMax) {
        this.updateState(
          "validationMessage",
          "Please enter max offer trade limit"
        );
        this.updateState("hasError", true);
        isError = true;
        this.scrollToTop();
      }  else if (Number(this.state.rangeMin) > Number(this.state.rangeMax)) {
        this.updateState(
          "validationMessage",
          "Please max amount must be greater than min amount"
        );
        this.updateState("hasError", true);
        isError = true;
        this.scrollToTop();
      } else if (this.state.amountValError === 1) {
        this.updateState(
          "validationMessage",
          "Please max amount must be greater than amount"
        );
        this.updateState("hasError", true);
        isError = true;
        this.scrollToTop();
      }
    }

    if (!isError) {
      this.setState({ step, hasError: false, validationMessage: "" });
    }
  };

  submitParams = () => {

    let tempFixedPrice = calcfixedPrice(this.state.tradeType, this.state.selectedCurrency)

    return {
      offer_type_field: this.state.tradeType,
      margin: this.state.margin,
      amount: parseFloat(parseFloat(this.state.amount).toFixed(2)),
      range_min: parseFloat(this.state.rangeMin),
      range_max: parseFloat(this.state.rangeMax),
      payment_window: this.state.paymentWindow,
      offer_terms: this.state.offerTerms,
      trade_details: this.state.selectedOnlineWallet?.details || this.state.tradeDetails,
      tags: JSON.stringify(this.state.tags),
      flow_type: this.state?.flowType || this.state.tradeType,
      offer_cap_status: true,
      offer_cap_amount: parseInt(this.state.rangeMax),
      fixed_price: tempFixedPrice,
      bank_accounts: JSON.stringify(this.state.selectedBankAccount.value),
      is_fixed_price: this.state.isFixedPrice,
      predefined_amount: parseInt(this.state.rangeMin),
      payment_method_label: this.state.selectedPaymentMethod.label,
      bank_reference_type: "account",
      bank_reference_message: this.state.selectedBankAccount.value,
      show_only_trusted_user: this.state.showOnlyTrustedUser,
      country_limitation_type: this.state.countryLimitationType,
      require_min_past_trades: this.state.requireMinPastTrades,
      require_verified_email: false,
      require_verified_phone: false,
      require_verified_id: this.state.requireVerifiedId,
      active: true,
      is_featured: false,
      duty_hours: this.state.dutyHours,
      payment_method_id: this.state.selectedPaymentMethodId,
      country_code: this.state.selectedCountry.value,
      crypto_currency_code: this.state.token,
      fiat_currency_code: this.state.selectedCurrency.value,
      user_country_code: this.state.selectedCountry.value,
      country_limitation_list: JSON.stringify(
        this.state.countryLimitationList || ""
      ),
      trade_type: this.state.tradeType,
      custom_offer_label: this.state.customOfferLabel,
    };
  };

  onSubmit = (e) => {
    e.preventDefault();

    if (this.state.tags === "" || this.state.tags?.length === 0) {
      this.updateState("validationMessage", "Please select at least one tag");
      this.updateState("hasError", true);
      this.scrollToTop();
      return;
    }

    if (this.state.customOfferLabel.length > 25) {
      this.updateState(
        "validationMessage",
        "Custom offer label cannot be more than 25 characters long contain only alphabets, numbers and dashes."
      );
      this.updateState("hasError", true);
      this.scrollToTop();
      return;
    }

    if (
      !this.state.customOfferLabel ||
      this.state.customOfferLabel.trim().length === 0 ||
      !this.state.offerTerms ||
      this.state.offerTerms.trim().length === 0
    ) {
      this.updateState(
        "validationMessage",
        "Please fill in all the required fields **"
      );
      this.updateState("hasError", true);
      this.scrollToTop();
      return;
    }

    const validateCustomOfferLabel = validateString(
      this.state.customOfferLabel
    );

    if (validateCustomOfferLabel) {
      this.updateState(
        "validationMessage",
        `Offer label cannot ${validateCustomOfferLabel}`
      );
      this.updateState("hasError", true);
      this.scrollToTop();
      return;
    }

    const validateOfferTerms = validateString(this.state.offerTerms);

    if (validateOfferTerms) {
      this.updateState(
        "validationMessage",
        `Offer terms cannot ${validateOfferTerms}`
      );
      this.updateState("hasError", true);
      this.scrollToTop();
      return;
    }

    this.setState({
      isSubmitting: true,
      hasError: false,
      validationMessage: "",
    });

    postRequest("/p2p_trades/offers", this.submitParams())
      .then((response) => {
        this.setState({
          isSubmitting: false,
          showModal: true,
        });
        const createOfferModal = document.querySelector("#createoffer-status-cont");
        if (createOfferModal) {
          createOfferModal.style.display = "flex";
        }
      })
      .catch((error) => {
        this.setState({ 
          isSubmitting: false,
          errorModal: true,
        });
        const createOfferModals = document.querySelector("#createoffer-status-cont");
        if (createOfferModals) {
          createOfferModals.style.display = "flex";
        }
        const errorDetails = error?.data?.errors?.[0];
        if (errorDetails) {
          const { attribute, error } = errorDetails;
          this.updateState(
            "validationMessage",
            `${attribute.replaceAll(/_/g, " ")} ${error}`
          );
          this.updateState("hasError", true);
          this.scrollToTop();
        }
      });
  };

  toggleModal = () => {
    this.setState((prevState) => ({
      showModal: !prevState.showModal,
    }));
  };

  closeModal = () => {
    const createOfferModal = document.querySelector("#createoffer-status-cont");
    if (createOfferModal) {
      createOfferModal.style.display = "none";
    }
  }

  handleProfanityDetection = (isProfanityDetected) => {
    this.setState({ isProfanityDetected });
  };

  render() {
    const {
      tradeType,
      tradeFees,
      token,
      step,
      selectedPaymentMethod,
      selectedCurrency,
      isSubmitting,
      margin,
      rangeMin,
      rangeMax,
      showModal,
      errorModal,
      isProfanityDetected,
    } = this.state;

    return (
      <div className="p2p-trades _create-offer mobile-p2p-trades createoffer-p2p-trades">
        <CheckBrokerStatus />
        <div className="create-offer-title">
          <p className="create-trade-page-title">
            <i class="fas fa-plus-circle"></i>
            Create{" "}
            {this.state.tradeType.charAt(0).toUpperCase() +
              this.state.tradeType.slice(1)}{" "}
            USD Offer
          </p>
        </div>

        <div>
          <div>
            <Header tradeType={tradeType} token={token} step={step} />
          </div>

          <div className={`step-info-cont ${tradeType === "sell" ? "step-info-cont-sell" : ""}`}>
            <div className="">
              {step === "pricing" ? (
                <div>
                  <p className="step-info-title">
                    <b><i className="fa fa-question-circle"></i>{" "}STEP 2:</b>
                    <span>
                      To set up your offer, choose your offer rate type, set offer amount, set offer limits, and set offer margin. You will pay BananaCrystal a <u>1.99% fee</u> for each trade.
                    </span>
                  </p>
                </div>) : step === "settings" ? (
                <div>
                  <p className="step-info-title">
                    <b><i className="fa fa-question-circle"></i>{" "}STEP 3:</b>
                    <span>To finalize your offer, add trade instructions, select at least one offer tag, choose a catchy broker nickname, and write clear and attractive offer terms.</span>
                  </p>
                </div>) : (
                <div>
                  <p className="step-info-title">            
                    <b><i className="fa fa-question-circle"></i>{" "}STEP 1:</b>
                    <span>Start creating your offer by selecting the currency you want
                  to trade, specifying whether you are buying or selling, and selecting your preferred payment method.</span>
                  </p>
                </div>
              )}     
            </div>
            {this.state.hasError && (
              <div className="createoffer-error-alert">
                {this.state.validationMessage}
              </div>
            )}
            <div className="create-offers">
              <Buy
                getData={this.getState}
                updateParent={this.updateState}
                step={step}
                token={token}
                handleProfanityDetection={this.handleProfanityDetection}
              />
            </div>
          </div>
          
          <div className="createoffer-buttons-parentsection">
            {step === "selectTrade" && (
              <div className="createoffer-buttons-section">
                <button
                  type="button"
                  className={`createoffer-next-submit-button ${tradeType === "sell" ? "createoffer-sell-btn" : ""}`}
                  onClick={(e) => this.updateNextStep(e, "pricing")}
                >
                   <span>Next Step</span>{" "}
                  <i class="fa-solid fa-arrow-right"></i>
                </button>
              </div>
            )}
            {step === "pricing" && (
              <div className="createoffer-buttons-section">
                <button
                  type="button"
                  className="createoffer-prev-cancel-button"
                  onClick={(e) => this.updateNextStep(e, "selectTrade")}
                >
                  <i class="fa-solid fa-arrow-left"></i>
                  <span>Previous Step</span>
                </button>
                <button
                  type="button"
                  className={`createoffer-next-submit-button ${tradeType === "sell" ? "createoffer-sell-btn" : ""}`}
                  onClick={(e) => this.updateNextStep(e, "settings")}
                >
                  <span>Next Step</span>{" "}
                  <i class="fa-solid fa-arrow-right"></i>
                </button>
              </div>
            )}
          
            {step === "settings" && (
              <div className="createoffer-buttons-section">
                <button
                  type="button"
                  className="createoffer-prev-cancel-button"
                  onClick={(e) => this.updateNextStep(e, "pricing")}
                >
                  Previous Step
                </button>
                <button
                  type="button"
                  className="createoffer-next-submit-button"
                  disabled={isSubmitting || isProfanityDetected}
                  onClick={this.onSubmit}
                >
                  {isSubmitting ? "Submitting" : "Submit"}
                </button>
                <br />
              </div>
            )}
          </div>

          {/* Modal section */}
          <div>
          {isSubmitting && (
            <div id="createoffer-status-cont">
              <div className="createoffer-status-section">
                <button
                  type="button"
                  className="close"
                  onClick={() => this.closeModal()}
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <p className="createoffer-loader">
                  <img src={bcloader} alt="loader" />
                  Please wait, your offer is being created...
                </p>
              </div>
            </div>
          )}

          {showModal && (
            <div id="createoffer-status-cont">
              <div className="createoffer-status-section">
                <CreateOfferModal 
                  statusMessage="Offer Created Successfully."
                  successStatus={true}
                />
              </div>
            </div>
          )}
           
    
          {errorModal && (
            <div id="createoffer-status-cont">
              <div className="createoffer-status-section">
              <button
                  type="button"
                  className="close"
                  onClick={() => this.closeModal()}
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <CreateOfferModal 
                  statusMessage="Offer creation failed! Check and confirm your details."
                  successStatus={false}
                />
              </div>
            </div>
          )} 
          </div>
        </div>
      </div>
    );
  }
}

export default New;
