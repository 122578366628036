import React, { Component, useState, useEffect } from "react";
import _ from "underscore";
import { getRequest, postRequest, patchRequest } from "../../utils/httpRequest";
import {
  validateWalletAddress,
  validateWalletAddressTag,
} from "../../model/walletAddress";
import AutoComplete from "../AutoComplete";
import WalletAddressesModal from "./WalletAddressesModal";
import paypalLogo from "../../../assets/images/payment_networks/paypal.png";
import cashappLogo from "../../../assets/images/payment_networks/cashapp.png";
import coinbaseLogo from "../../../assets/images/payment_networks/coinbase.png";
import binanceLogo from "../../../assets/images/payment_networks/binance.png";
import kucoinLogo from "../../../assets/images/payment_networks/kucoin.png";
import robinhoodLogo from "../../../assets/images/payment_networks/robinhood.png";
import { formErrors } from "../../utils/formErrors";
import CryptoForm from "./CryptoForm";

const transferTypes = [
  {
    label: "Recipient's Wallet",
    value: "recipient",
  },
  {
    label: "My Wallet",
    value: "my-wallet",
  },
];

class CryptoTransferSendBC extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedSourceWallet: props.selectedSourceWallet,
      selectedDestinationWallet: "",
      destinationWallets: "",
      // selectedCurrency: props.selectedSourceToken,
      selectedCurrency: "USDT",
      selectedCurrencyBalance:
        props.balances["USDT"] || parseFloat(0.0).toFixed(2),
      balances: props.balances,
      selectedTransferType: "recipient",
      recipient: props.recipient || "",
      recipients: props.recipients || [],
      amount: parseFloat(0.0).toFixed(2),
      sourceAmount: parseFloat(0.0).toFixed(2),
      netAmount: parseFloat(0.0).toFixed(2),
      recipientError: "",
      error: "",
      amountError: "",
      mode: "form",
      invalidTransfer: false,
      submitting: false,
      validationErrors: {},
      fees: parseFloat(0.0).toFixed(2),
      firstTimeWithdrawalFee: parseFloat(0.0).toFixed(2),
      blockchainAddress: "",
      blockchainAddressTag: "",
      walletAddresses: props.walletAddresses || [],
      canSubmitTransfer: false,
      selectedWalletAddress: "",
      lookedUpRecipient: {},
      withdraw: props.withdraw || false,
    };
  }

  componentDidMount() {}

  showModal = () => {
    $("#walletAddressesModal").modal("show");
  };

  closeModal = () => {
    $("#walletAddressesModal").modal("hide");
  };

  getChainWalletAddress = (chain) => {
    getRequest(`/crypto_transfers/chain_wallet_addresses?chain=${chain}`).then(
      (addresses) => {
        const walletAddresses = addresses.map((address) => {
          return {
            label: address.wallet_label,
            address: address.address,
            addressTag: address.tag,
            value: address.id,
          };
        });

        this.setState({ walletAddresses });
      }
    );
  };

  handleChange = (event, key) => {
    this.setState({ [key]: event.target.value });

    if (key === "selectedTransferType") {
      this.setState({ invalidTransfer: false });
    }
  };

  lookupUser = () => {
    const { validationErrors } = this.state;

    if (this.state.recipient) {
      const data = {
        crypto_transfer: {
          recipient: this.state.recipient,
        },
      };

      const crsfToken = $('meta[name="csrf-token"]').attr("content");

      postRequest("/crypto_transfers/lookup_recipient", data, {
        "X-CSRF-Token": crsfToken,
      })
        .then((response) => {
          validationErrors.recipientError = "";
          this.setState({
            validationErrors,
            invalidTransfer: false,
            lookedUpRecipient: response,
          });
        })
        .catch((error) => {
          validationErrors.recipientError = error.data.error;

          this.setState({
            validationErrors,
            invalidTransfer: true,
          });
        });
    }
  };

  handleAmountChange = (e) => {
    this.setState({ amount: e.target.value }, () => this.validateAmount());
  };

  handleBlockchainAddressChange = (e) => {
    this.setState(
      {
        blockchainAddress: e.target.value,
      },
      () => this.validateBlockchainAddress()
    );
    this.validateBlockchainAddressTag();
  };

  handleBlockchainAddressTagChange = (e) => {
    this.setState({ blockchainAddressTag: e.target.value }, () =>
      this.validateBlockchainAddressTag()
    );
    this.validateBlockchainAddress();
  };

  handleSelectedWalletAddress = (e) => {
    this.setState({ selectedWalletAddress: e.target.value }, () =>
      this.setBlockchainAddress(e.target.value)
    );
    this.validateBlockchainAddress();
  };

  handleSelectedCurrencyChange = (e) => {
    this.setState({ selectedCurrency: e.target.value }, () =>
      this.getChainWalletAddress(e.target.value)
    );
    // console.log('balances', this.state.balances);
    // console.log('selectedBalance', this.state.balances[e.target.value]);
    this.setState({
      selectedCurrencyBalance: this.state.balances[e.target.value],
    });
  };

  setBlockchainAddress = (val) => {
    const { walletAddresses, validationErrors } = this.state;

    const walletAddress = _.find(walletAddresses, function (address) {
      return address.value === val;
    });

    if (undefined === walletAddress) {
      this.setState({ blockchainAddress: "" });
      this.setState({ blockchainAddressTag: "" });
      this.setState({ validationErrors: {} });
    } else {
      this.setState({ blockchainAddress: walletAddress.address });
      this.setState({ blockchainAddressTag: walletAddress.addressTag });
    }
  };

  validateBlockchainAddress = () => {
    const {
      selectedCurrency,
      blockchainAddress,
      blockchainAddressTag,
      validationErrors,
    } = this.state;
    const currency = selectedCurrency.toUpperCase();

    const chain = ["OPAIG"].includes(currency) ? currency : "OPAIG";

    const validAddress = validateWalletAddress("ETH", blockchainAddress);

    if (validAddress) {
      validationErrors.blockchainAddressError = "";
    } else {
      validationErrors.blockchainAddressError = "Address is invalid";
    }

    this.setState({ validationErrors });
  };

  validateBlockchainAddressTag = () => {
    const {
      selectedCurrency,
      blockchainAddress,
      blockchainAddressTag,
      validationErrors,
    } = this.state;
    const currency = selectedCurrency.toUpperCase();

    const chain = ["OPAIG"].includes(currency) ? currency : "OPAIG";

    const validAddressTag = validateWalletAddressTag(
      chain,
      blockchainAddressTag
    );

    if (validAddressTag) {
      validationErrors.blockchainAddressTagError = "";
    } else {
      validationErrors.blockchainAddressTagError =
        "Address tag/memo is missing or not required";
    }

    this.setState({ validationErrors });
  };

  validateAmount = () => {
    // console.log('validateAmount state', this.state)
    const { validationErrors } = this.state;
    this.setState({
      error: "",
      selectedCurrencyBalance: this.state.balances[this.state.selectedCurrency],
    });

    let totalAmount =
      Number(this.state.amount) +
      Number(this.state.fees) +
      Number(this.state.firstTimeWithdrawalFee);

    if (Number(this.state.amount) <= 0) {
      validationErrors.amountError =
        "Amount to transfer cannot be zero. Please enter a higher amount.";

      this.setState({
        validationErrors,
        invalidTransfer: true,
      });
    } else if (totalAmount > Number(this.state.selectedCurrencyBalance)) {
      validationErrors.amountError =
        "Total amount to transfer is greater than your available balance, please enter a lower amount.";

      this.setState({
        validationErrors,
        invalidTransfer: true,
      });
    } else {
      validationErrors.amountError = "";
      this.setState({
        validationErrors,
        invalidTransfer: false,
      });
    }
  };

  handleRecipientChange = (value, userExists = false) => {
    const { validationErrors } = this.state;

    validationErrors.recipientError = "";
    this.setState({ invalidTransfer: false });

    this.setState({ recipient: value, recipientError: "" }, () => {
      if (!userExists) {
        this.lookupUser();
      }
    });
  };

  validateFormFields = () => {
    const { amount, recipient, selectedTransferType, blockchainAddress } =
      this.state;
    const { type } = this.props;

    let validationErrors = {};

    this.validateAmount();

    if (
      type === "wallet" &&
      selectedTransferType === "recipient" &&
      !recipient
    ) {
      validationErrors.recipientError = "Recipient is required";
    }

    if (type === "blockchain" && !blockchainAddress) {
      validationErrors.blockchainAddressError =
        "Blockchain address is required";
    }

    return validationErrors;
  };

  refreshPage = (e) => {
    e.preventDefault();
    const url = window.location.href;
    window.location.assign(url);
  };

  setApiErrors = (errors) => {
    const formattedErrors = formErrors(errors);

    const { validationErrors } = this.state;

    const amountError = formattedErrors["gross_amount"];
    const recipientError = formattedErrors["destination_wallet_id"];

    validationErrors.amountError = amountError;
    validationErrors.recipientError = recipientError;

    return validationErrors;
  };

  submitTransferPreview = (e) => {
    const {
      selectedSourceWallet,
      selectedDestinationWallet,
      destinationWallets,
      recipient,
      amount,
      selectedCurrency,
      selectedTransferType,
      blockchainAddress,
      blockchainAddressTag,
      selectedCurrencyBalance,
    } = this.state;

    e.preventDefault();

    const validationErrors = this.validateFormFields();

    if (!_.isEmpty(validationErrors)) {
      this.setState({ validationErrors, invalidTransfer: true });

      return;
    }

    this.setState({ submitting: true, error: "", validationErrors: {} });

    const data = {
      crypto_transfer: {
        total_type: "total_to_withdraw",
        source_wallet_id: selectedSourceWallet,
        recipient: selectedTransferType === "recipient" ? recipient : "",
        destination_wallet_id: selectedDestinationWallet,
        gross_amount: amount,
        source_currency: selectedCurrency,
        net_amount: amount,
        net_amount_currency: selectedCurrency,
        destination_type: this.props.type,
        blockchain_address: blockchainAddress,
        selected_currency_balance: selectedCurrencyBalance,
        type: "CryptoTransfers::BcCryptoWithdrawal",
      },
    };

    const crsfToken = $('meta[name="csrf-token"]').attr("content");

    postRequest("/crypto_transfers/send_crypto", data, {
      "X-CSRF-Token": crsfToken,
    })
      .then((response) => {
        this.setState({
          fees: response.fees,
          firstTimeWithdrawalFee: response.first_time_withdrawal_fee,
          canSubmitTransfer: true,
          sourceAmount: response.source_amount,
          netAmount: response.destination_amount,
          transferId: response.transfer_id,
        });
        this.validateAmount();
      })
      .catch((error) => {
        console.log(error);

        this.setState({ error: error?.data?.error });

        const errors = this.setApiErrors(error?.data?.errors);
        this.setState({ validationErrors: errors, submitting: false });
      });
  };

  submitTransfer = (e) => {
    const { transferId } = this.state;

    e.preventDefault();

    getRequest(`/crypto_transfers/${transferId}/resend_confirmation`).then(
      () => {
        window.location = `/crypto_transfers/${transferId}`;
      }
    );
  };

  cancelTransfer = (e) => {
    const { transferId } = this.state;

    e.preventDefault();

    const data = {
      crypto_transfer: {
        id: transferId,
      },
    };

    const crsfToken = $('meta[name="csrf-token"]').attr("content");

    patchRequest(`/crypto_transfers/${transferId}/cancel`, data, {
      "X-CSRF-Token": crsfToken,
    })
      .then(() => {
        // window.location = `/crypto_transfers/`;
        this.refreshPage(e);
        // window.location = `/crypto_transfers/new_blockchain_to_usd_transfer?token=${selectedCurrency}`;
      })
      .catch((error) => {
        this.setState({ submitting: false });
        console.log(error);
      });
  };

  render() {
    const { walletAddresses, selectedCurrency } = this.state;

    return (
      <div className="container-fluid">
        <CryptoForm
          stateParams={this.state}
          propsParams={this.props}
          handleChange={this.handleChange}
          handleBlockchainAddressChange={this.handleBlockchainAddressChange}
          showModal={this.showModal}
          cancelTransfer={this.cancelTransfer}
          refreshPage={this.refreshPage}
          submitTransfer={this.submitTransfer}
          submitTransferPreview={this.submitTransferPreview}
          handleAmountChange={this.handleAmountChange}
          handleRecipientChange={this.handleRecipientChange}
          handleSelectedCurrencyChange={this.handleSelectedCurrencyChange}
        />

        <WalletAddressesModal
          walletAddresses={walletAddresses}
          availableWalletAddresses={walletAddresses}
          selectedCurrency={selectedCurrency}
          closeModal={this.closeModal}
          handleSelectedWalletAddress={this.handleSelectedWalletAddress}
        />
      </div>
    );
  }
}

export default CryptoTransferSendBC;
