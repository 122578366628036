import React, { useEffect, useState } from "react";
import countries from "../../../../../utils/countries.json";

interface Props {
  attribute: string;
  allLabel: string;
  iconClass: string;
  items: string[];
  label: string;
  handleFilter: (value: string, allLabel: string) => void;
  preferredValue?: string;
}

export const MenuSelect: React.FC<Props> = ({
  attribute,
  allLabel,
  iconClass,
  items,
  label,
  handleFilter,
  preferredValue,
}) => {
  const [selectedValue, setSelectedValue] = useState("");
  const [isPreferredInItems, setIsPreferredInItems] = useState(false);

  useEffect(() => {
    if (preferredValue) {
      if (!items.includes(preferredValue)) {
        items.push(preferredValue);
      }
      setSelectedValue(preferredValue);
      setIsPreferredInItems(true);
      handleFilter("label", preferredValue);
    }
  }, [preferredValue, items, allLabel]);

  const handleChange = (event) => {
    const value = event.target.value;
    setSelectedValue(value);
    handleFilter(label, value);
  };

  const icon = iconClass === "globe" ? "" : iconClass === "money" ? "" : "";
  const itemLabel = (value) =>
    countries.find((country) => country.code2 === value)?.name;

  return (
    <div className="offers-filters">
      <select value={selectedValue} onChange={handleChange}>
        {allLabel && (
          <option value="">
            {icon}
            {allLabel}
          </option>
        )}
        {items.map((item, index) => (
          <option key={index} value={item}>
            {icon}
            {label === "country" ? itemLabel(item) : item}
          </option>
        ))}
      </select>
      <span className="filter-icon">&nbsp;</span>
    </div>
  );
};

export default MenuSelect;
