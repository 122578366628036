import React, { useState } from "react";
import { Field, FormikHelpers, ErrorMessage } from "formik";
import { email, required } from "../../utils/formValidations";

const StepTwo = ({
  errors,
  apiErrors,
  validateForm,
  handleChange,
  handleNextStepChange,
  handlePreviousStepChange
}) => (
  <>
    <div className="form-group row required">
      <label htmlFor="paymentEmail" className="form-label">
        Payment Email
      </label>
      <p>
        Your payment email. You will also receive all transactions and
        notifications to this email. Customers will use your payment email to
        find your store/business. Example: orders@mystore.com or
        accounting@mysuperstore.com
      </p>
      <Field
        id="paymentEmail"
        name="paymentEmail"
        className="form-control"
        validate={email}
        onChange={handleChange}
      />
      {errors.paymentEmail && (
        <div className="error">{errors.paymentEmail}</div>
      )}
      {apiErrors?.storePaymentEmailError && (
        <div className="error text-danger">
          {apiErrors.storePaymentEmailError}
        </div>
      )}
    </div>
    <div className="form-group row required">
      <label htmlFor="supportEmail" className="form-label">
        Support Email
      </label>
      <p>
        Your customer support email. This can be the same or different than your
        payment email. Customers can contact you regarding any issues with their
        payment or orders. Example: support@mystore.com or help@mysuperstore.com
      </p>
      <Field
        id="supportEmail"
        name="supportEmail"
        validate={email}
        className="form-control"
        onChange={handleChange}
      />
      {errors.supportEmail && (
        <div className="error">{errors.supportEmail}</div>
      )}
      {apiErrors?.storeSupportEmailError && (
        <div className="error text-danger">
          {apiErrors.storeSupportEmailError}
        </div>
      )}
    </div>
    <div className="form-group row required">
      <label htmlFor="address" className="form-label">
        Address
      </label>
      <Field
        as="textarea"
        id="address"
        name="address"
        className="form-control"
        onChange={handleChange}
      />
      {errors.address && <div className="error">{errors.address}</div>}
      {apiErrors?.storeAddressError && (
        <div className="error text-danger">{apiErrors.storeAddressError}</div>
      )}
    </div>
    <div className="form-group row">
      <label htmlFor="phone" className="form-label">
        Telephone
      </label>
      <Field
        id="phone"
        name="phone"
        className="form-control"
        onChange={handleChange}
      />
      {errors.phone && <div className="error">{errors.phone}</div>}
      {apiErrors?.phoneError && (
        <div className="error text-danger">{apiErrors.phoneError}</div>
      )}
    </div>
    <div className="form-group row">
      <label htmlFor="website" className="form-label">
        Website or Mobile Application
      </label>
      <p>
        The website or mobile application for your business or url of the
        application that will integrate and accept payments
      </p>
      <Field
        id="website"
        name="website"
        className="form-control"
        onChange={handleChange}
      />
      {errors.website && <div className="error">{errors.website}</div>}
      {apiErrors?.storeUrlError && (
        <div className="error text-danger">{apiErrors.storeUrlError}</div>
      )}
    </div>

    <div className="action">
      <button
        type="button"
        className="btn btn-primary left"
        onClick={() => handlePreviousStepChange("one")}
      >
        Back
      </button>
      <button
        type="button"
        className="btn btn-primary right"
        onClick={e => handleNextStepChange(validateForm, "three")}
      >
        Next
      </button>
    </div>
  </>
);

export default StepTwo;
