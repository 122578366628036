// Filter reviews based on hit?.profile?.Id
export const getRating = (profileId, reviews) => {
  if (!profileId || reviews.length === 0) return "No Rating";

  const profileReviews = reviews.filter(
    (review) => review.reviewedProfileId === profileId
  );

  if (profileReviews.length === 0) return "No Rating";

  const totalProfileReviews = profileReviews.length;
  const totalProfileUpvotes = profileReviews.filter(
    (review) => review.vote === "upvote"
  ).length;

  const rating = (totalProfileUpvotes / totalProfileReviews) * 100;
  return Math.floor(rating) + "%";
};
