import React, { useState, useEffect } from "react";
import { Field, FormikErrors, FormikHelpers } from "formik";
import countries from "../../../../../utils/countries.json";
import { Option } from "react-select";
import SelectField from "../common/SelectField";
import { cardNetworkOptions } from "./helpers";
import { required } from "../../../../../utils/formValidations";
import { IconLocationPurple } from "../../../../Dashboard/dashboardIcons";

interface Props {
  selectedCountry: string;
  apiErrors?: any;
  errors: FormikErrors<{
    card_network: string;
    address: string;
    city: string;
    state: string;
    country: string;
    postal_code: string;
  }>;
  handlePreviousStepChange: (step: string) => void;
  handleNextStepChange: (
    validateForm: () => Partial<FormikHelpers<any>>,
    step: string
  ) => void;
  setFieldValue: (field: string, value: any) => void;
  validateForm: () => Partial<FormikHelpers<any>>;
}

const MailingDetails: React.FC<Props> = ({
  selectedCountry,
  errors,
  apiErrors,
  handlePreviousStepChange,
  handleNextStepChange,
  setFieldValue,
  validateForm,
}) => {
  useEffect(() => {
    if (selectedCountry) {
      filterStates(selectedCountry);
    }
  }, []);

  useEffect(() => {
    // Initialize all tooltips
    ($('[data-toggle="tooltip"]') as any).tooltip();
  }, []);

  const [stateOptions, setStateOptions] = useState([]);

  const countryOptions = countries
    .filter((country) => country.states.length > 0)
    .map((country) => {
      return {
        value: country.name,
        label: country.name,
      };
    });

  const filterStates = (selectedCountry) => {
    const country = countries.find((ctry) => {
      return ctry.name == selectedCountry;
    });

    if (country) {
      const states = country.states.map((state) => {
        return {
          label: state.name,
          value: state.name,
        };
      });

      setStateOptions(states);
    }
  };

  return (
    <>
      <div className="card-body">
        <div id="mailing-details">
          <div className="w-100 d-flex align-items-center">
            <h4>
              <IconLocationPurple /> Card Mailing Address{" "}
              <i
                data-toggle="tooltip"
                title="Please provide a complete and accurate physical address where you can receive your card."
                className="fa fa-info-circle"
              ></i>
            </h4>
          </div>
          <div className="row card-deck mb-5">
            <form className="col-md-12">
              <div className="row">
                <div className="col-md-6 form-group">
                  <label htmlFor="address">Address</label>
                  <Field
                    name="address"
                    className={`form-control ${
                      errors.address || apiErrors?.address_error
                        ? "is-invalid"
                        : ""
                    }`}
                    validate={required}
                    error={errors.address || apiErrors?.address_error}
                  />
                  {errors.address && !apiErrors?.address_error && (
                    <small className="error">{errors.address}</small>
                  )}
                  {apiErrors?.address_error && (
                    <small className="error text-danger">
                      {apiErrors.address_error}
                    </small>
                  )}
                </div>
                <div className="col-md-6 form-group">
                  <label htmlFor="country">Country</label>
                  <Field
                    name="country"
                    className={`form-control ${
                      errors.country || apiErrors?.country_error
                        ? "is-invalid"
                        : ""
                    }`}
                    component={SelectField}
                    validate={required}
                    options={countryOptions}
                    onChange={(option: Option) => {
                      setFieldValue("country", option.value);
                      filterStates(option.value);
                    }}
                    error={errors.country || apiErrors?.country_error}
                  />
                  {errors.country && !apiErrors?.country_error && (
                    <small className="error">{errors.country}</small>
                  )}
                  {apiErrors?.country_error && (
                    <small className="error text-danger">
                      {apiErrors.country_error}
                    </small>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 form-group">
                  <label htmlFor="city">City</label>
                  <Field
                    name="city"
                    className={`form-control ${
                      errors.city || apiErrors?.city_error ? "is-invalid" : ""
                    }`}
                    validate={required}
                    placeholder="Enter Your City"
                    error={errors.city || apiErrors?.city_error}
                  />
                  {errors.city && !apiErrors?.city_error && (
                    <small className="error">{errors.city}</small>
                  )}
                  {apiErrors?.city_error && (
                    <small className="error text-danger">
                      {apiErrors.city_error}
                    </small>
                  )}
                </div>
                <div className="col-md-6 form-group">
                  <label htmlFor="state">State</label>
                  <Field
                    name="state"
                    className={`form-control ${
                      errors.state || apiErrors?.state_error ? "is-invalid" : ""
                    }`}
                    validate={required}
                    component={SelectField}
                    options={stateOptions}
                    error={errors.state || apiErrors?.state_error}
                  />
                  {errors.state && !apiErrors?.state_error && (
                    <small className="error">{errors.state}</small>
                  )}
                  {apiErrors?.state_error && (
                    <small className="error text-danger">
                      {apiErrors.state_error}
                    </small>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 form-group">
                  <label htmlFor="postal_code">Postal Code</label>
                  <Field
                    className={`form-control ${
                      errors.postal_code || apiErrors?.postal_code_error
                        ? "is-invalid"
                        : ""
                    }`}
                    name="postal_code"
                    validate={required}
                    placeholder="Enter your postal code"
                    error={errors.postal_code || apiErrors?.postal_code_error}
                  />
                  {errors.postal_code && !apiErrors?.postal_code_error && (
                    <small className="error">{errors.postal_code}</small>
                  )}
                  {apiErrors?.postal_code_error && (
                    <small className="error text-danger">
                      {apiErrors.postal_code_error}
                    </small>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="p-3">
        <div className="d-flex flex-row justify-content-between">
          <button
            type="button"
            className="btn btn-outline-purple float-left"
            onClick={(e) => handlePreviousStepChange("identification")}
          >
            <i className="fa fa-arrow-left"></i> Previous
          </button>
          <button
            type="button"
            className="btn btn-purple float-right"
            onClick={(e) => handleNextStepChange(validateForm, "preview")}
          >
            Next <i className="fa fa-arrow-right"></i>
          </button>
        </div>
      </div>
    </>
  );
};

export default MailingDetails;
