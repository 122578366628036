import React, { Component } from "react";
import Select from "react-select";
import {
  getRequest,
  postRequest,
} from "../../../../../../../utils/httpRequest";
import AddAccountModal from "./AddAccountModal";
import BCLoader from "../../../../../Loaders/bcloader"

class BankAccount extends Component {
  constructor(props) {
    super(props);

    this.state = {
      bankAccounts: [],
      isAccountModalOpen: false,
      step: "one",
      accountType: "personal",
      bankName: "",
      accountName: "",
      customBankDetails: "",
      accountNumber: "",
      routingNumber: "",
      sortCode: "",
      swiftCode: "",
      clabe: "",
      selectedBankAccount: {},
      iban: "",
      countryOfResidence: "",
      state: "",
      city: "",
      zipCode: "",
      address: "",
      interacEmail: "",
      bankAccountCountry: props.selectedCountry,
      bankAccountCurrency: props.selectedCurrency,
      isSubmitting: false,
      apiErrors: {},
    };
  }

  componentDidMount() {
    this.getBankAccounts();
    this.setState({ selectedBankAccount: this.props.getData('selectedBankAccount') });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedCountry !== this.props.selectedCountry) {
      this.setState({ bankAccountCountry: this.props.selectedCountry });
    }

    if (prevProps.selectedCurrency !== this.props.selectedCurrency) {
      this.setState({ bankAccountCurrency: this.props.selectedCurrency });
    }
  }

  getBankAccounts = () => {
    this.setState({ isLoadingBankAccounts: true });
    getRequest("/p2p_trades/bank_accounts").then((response) => {
      const bankAccounts = response.map((account) => ({
        label: `${account.accountHolderName}, ${account.fiatCurrency.code}, ${account.bankName}, ${account.accountNumber}`,
        value: account,
      }));

      this.setState({
        bankAccounts,
        isLoadingBankAccounts: false,
      });
    });
  };

  toggleOpenBankAccountModal = (e) => {
    e.preventDefault();

    this.setState({ isAccountModalOpen: !this.state.isAccountModalOpen });
  };

  handleBankAccountChange = (selectedBankAccount) => {
    this.setState({ selectedBankAccount });

    this.props.updateParent("selectedBankAccount", selectedBankAccount);
  };

  updateBankAccounts = (newAccount) => {
    this.setState((prevState) => ({
      bankAccounts: [...prevState.bankAccounts, newAccount],
      selectedBankAccount: newAccount,
    }));
  };

  handleStepChange = (e, step) => {
    e.preventDefault();

    this.setState({ step });
  };

  handleInputChange = (e, key) => {
    this.setState({ [key]: e.target.value });
  };

  handleCurrencyChange = (bankAccountCurrency) => {
    this.setState({ bankAccountCurrency });
  };

  handleCountryChange = (bankAccountCountry) => {
    this.setState({ bankAccountCountry });
  };

  handleBankNameChange = (bankName) => {
    this.setState({ bankName: bankName?.value });
  };

  onSubmit = (e) => {
    e.preventDefault();

    this.setState({ isSubmitting: true });

    const { selectedPaymentMethod } = this.props;

    const {
      city,
      state,
      address,
      zipCode,
      bankAccountCountry,
      bankAccountCurrency,
      bankName,
      accountName,
      accountNumber,
      swiftCode,
      interacEmail,
      clabe,
      customBankDetails,
      countryOfResidence,
      routingNumber,
      iban,
    } = this.state;

    if (!accountName.trim().length > 0) {
      this.setState({ isSubmitting: false });
      this.setState({ apiErrors:  {"error": "Account name cannot be empty or consist only of spaces."}});
      return;
    }

    const params = {
      city,
      state,
      address,
      zip_code: zipCode,
      country: bankAccountCountry.value,
      currency: bankAccountCurrency.value,
      bank_name: bankName,
      account_name: accountName,
      account_number: accountNumber,
      routing_number: routingNumber,
      swift_code: swiftCode,
      clabe: clabe,
      interac_email: interacEmail,
      custom_bank_details: customBankDetails,
      country_of_residence: countryOfResidence,
      is_international_transfer:
        selectedPaymentMethod.value === "international-wire-transfer-swift",
      iban_number: iban,
    };
    this.setState({ isSubmitting: true, apiErrors: {} }); // Clear previous errors

    postRequest("/p2p_trades/bank_accounts", params)
      .then((response) => {
        const selectBank = {
          label: `${accountName}, ${bankAccountCurrency.value}, ${bankName}, ${accountNumber}`,
          value: response.data,
        };
        this.updateBankAccounts(selectBank);

        this.setState({
          isSubmitting: false,
        });

        this.props.updateParent("selectedBankAccount", selectBank);
        this.toggleOpenBankAccountModal(e);
      })
      .catch((error) => {
        this.setState({ isSubmitting: false });
        if (error.data) {
          this.setState({ apiErrors: error.data.error });
        }
        console.log(error);
      });
  };

  render() {
    const {
      countries,
      currencies,
      selectedPaymentMethod,
      handleCountryChange,
      selectedCountry,
    } = this.props;

    const {
      isAccountModalOpen,
      step,
      accountType,
      bankAccountCountry,
      bankAccountCurrency,
      bankName,
      accountName,
      customBankDetails,
      accountNumber,
      sortCode,
      swiftCode,
      interacEmail,
      clabe,
      iban,
      countryOfResidence,
      state,
      city,
      zipCode,
      bankAccounts,
      selectedBankAccount,
      address,
      isSubmitting,
      apiErrors,
    } = this.state;

    return (
      <>
        {["bank-transfer"].includes(selectedPaymentMethod?.value) && (
          <>
            <div className="col-md-12 px-0">
              <br />
              <label><b>Country <span style={{ color: "red" }}>*</span></b></label>
              <Select
                className="currencies-select-container mb-2"
                options={countries}
                value={selectedCountry}
                onChange={handleCountryChange}
              />
              <label>
                Select the country where you’re using your bank account(s).
              </label>
            </div>
            <div className="col-md-12 mt-3 px-0">
              <div className="px-0">
                <label><b>Select Your Bank Account <span style={{ color: "red" }}>*</span></b></label>
                <Select
                  className="currencies-select-container"
                  options={bankAccounts}
                  value={selectedBankAccount}
                  onChange={this.handleBankAccountChange}
                  placeholder="Select your bank account"
                />
                <span className="font-0_9">
                  Once the trade begins, we’ll automatically share your selected
                  account details with your trade partner.
                </span>
              </div>
              <br/>
              <label><b> <i class="fa fa-info-circle"></i>{" "}
              Can't find your preferred Bank Account?</b></label>
              <button
                type="button"
                className="createoffer-default-buttons  mb-3"
                onClick={this.toggleOpenBankAccountModal}
              >
                <i className="fa fa-plus" aria-hidden="true"></i>
                Add Bank Account
              </button>
              <br />
            </div>
            {isAccountModalOpen && (
              <AddAccountModal
                handleCloseModal={this.toggleOpenBankAccountModal}
                countries={countries}
                currencies={currencies}
                step={step}
                accountType={accountType}
                bankAccountCountry={bankAccountCountry}
                bankAccountCurrency={bankAccountCurrency}
                bankName={bankName}
                accountName={accountName}
                customBankDetails={customBankDetails}
                accountNumber={accountNumber}
                sortCode={sortCode}
                swiftCode={swiftCode}
                clabe={clabe}
                interacEmail={interacEmail}
                iban={iban}
                countryOfResidence={countryOfResidence}
                state={state}
                city={city}
                zipCode={zipCode}
                address={address}
                isSubmitting={isSubmitting}
                handleStepChange={this.handleStepChange}
                handleInputChange={this.handleInputChange}
                handleCountryChange={this.handleCountryChange}
                handleCurrencyChange={this.handleCurrencyChange}
                handleBankNameChange={this.handleBankNameChange}
                onSubmit={this.onSubmit}
                apiErrors={apiErrors}
              />
            )}
          </>
        )}
      </>
    );
  }
}

export default BankAccount;
