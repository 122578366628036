import React, { useState } from "react";
import {
  Formik,
  Form,
  Field,
  FormikHelpers,
  FieldArray,
  ErrorMessage
} from "formik";
import { url } from "./validations";
import { postRequest, putRequest } from "../../utils/httpRequest";
import { formErrors } from "../../utils/formErrors";

import woocommerceWordpressLogo from "../../../assets/images/integrations/woocommerce-wordpress.png";

const WordPressForm = ({ store_id, integration }) => {
  const [creating, setCreating] = useState(false);
  const [errors, setErrors] = useState({});

  const formattedErrors = errors => {
    const validationErrors = {};
    const redirectUrlError = errors["redirect_url"];
    const returnUrlError = errors["return_url"];
    const alternateRedirectUrlError = errors["alternate_redirect_url"];
    const webhookUrlError = errors["webook_url"];

    validationErrors.redirectUrlError = redirectUrlError;
    validationErrors.returnUrlError = returnUrlError;
    validationErrors.alternateRedirectUrlError = alternateRedirectUrlError;
    validationErrors.webhookUrlError = webhookUrlError;

    return validationErrors;
  };

  const submit = values => {
    const crsfToken = $('meta[name="csrf-token"]').attr("content");

    const data = {
      store_integration: values
    };
    setCreating(true);

    if (integration) {
      putRequest(`/stores/${store_id}/integrations/${integration.id}`, data, {
        "X-CSRF-Token": crsfToken
      })
        .then(response => {
          window.location = `/stores/${store_id}/integrations`;
        })
        .catch(error => {
          const errors = formErrors(error.data.errors);

          setErrors(error);
          setCreating(false);
        });
    } else {
      postRequest(`/stores/${store_id}/integrations`, data, {
        "X-CSRF-Token": crsfToken
      })
        .then(response => {
          window.location = `/stores/${store_id}/integrations`;
        })
        .catch(error => {
          const errors = formErrors(error.data.errors);

          setErrors(error);
          setCreating(false);
        });
    }
  };

  return (
    <Formik
      initialValues={{
        redirect_url: integration?.redirect_url || "",
        return_url: integration?.return_url || "",
        webhook_url: integration?.webhook_url || "",
        enable_subscriptions: integration?.enable_subscriptions || false,
        alternate_redirect_url: integration?.alternate_redirect_url || "",
        integration_type: "WooCommerce Plugin"
      }}
      onSubmit={values => submit(values)}
    >
      {({ values, touched, handleChange }) => (
        <Form className="form">
          <div className="text-muted font-0_8">
            <h5 className="pl-2">
              {" "}
              <img src={woocommerceWordpressLogo} width="180" /> Quick & Easy
              Install
            </h5>
            <div id="integrationsWoocommerceHelp">
              <div className="pl-2 ml-3 mb-3">
                <i className="fa fa-question-circle"></i>{" "}
                <a
                  href="https://www.bananacrystal.com/docs/how-to-install-the-wordpress-woocommerce-plugin/"
                  target="_blank"
                >
                  How to Install the Wordpress Woocomerce Plugin
                </a>
              </div>
            </div>
          </div>
          <div className="text-center pt-2 pb-2">
            <hr />
            <h4 className="pt-2 pb-2">Settings</h4>
            <hr />
          </div>
          <div className="pt-2 pb-2">
            <div className="form-group row required">
              <label htmlFor="redirect_url" className="form-label">
                Order Completion or Thank You Page URL
              </label>
              <Field
                id="redirect_url"
                name="redirect_url"
                className="form-control font-0_75"
                validate={url}
                onChange={handleChange}
              />
              <ErrorMessage name="redirect_url">
                {msg => <div className="error">{msg}</div>}
              </ErrorMessage>
              {errors?.redirectUrlError && (
                <div className="error text-danger">
                  {validationErrors.redirectUrlError}
                </div>
              )}
              <span className="text-muted font-0_75">
                The thank you page for your WooCommerce store where your
                customers will be redirected after they complete their payment
                (Redirect URL).
                <br />
                <br />
                Example: https://mystore.com/checkout/order-received/
                <strong>order_id</strong>/?key=wc_order_<strong>key</strong> .
                Note the order_id and key will be replaced with your customer's
                actual order id and key.
              </span>
            </div>
          </div>
          <div className="pt-2 pb-2">
            <div className="form-group row required">
              <label htmlFor="redirect_url" className="form-label">
                Order Pay or Return Page URL
              </label>
              <Field
                id="return_url"
                name="return_url"
                className="form-control font-0_75"
                validate={url}
                onChange={handleChange}
              />
              <ErrorMessage name="return_url">
                {msg => <div className="error">{msg}</div>}
              </ErrorMessage>
              {errors?.returnUrlError && (
                <div className="error text-danger">
                  {validationErrors.returnUrlError}
                </div>
              )}
              <span className="text-muted font-0_75">
                The order pay page for your WooCommerce store where your
                customers will be redirected when they click on a return link
                (Return URL).
                <br />
                <br />
                Example: https://mystore.com/checkout/order-pay/
                <strong>order_id</strong>/?key=wc_order_<strong>key</strong> .
                Note the order_id and key will be replaced with your customer's
                actual order id and key.
              </span>
            </div>
          </div>
          <div className="form-group row required">
            <label htmlFor="webhook_url" className="form-label">
              Payment Notifications URL
            </label>
            <Field
              id="webhook_url"
              name="webhook_url"
              className="form-control font-0_75"
              validate={url}
              onChange={handleChange}
            />
            <ErrorMessage name="webhook_url">
              {msg => <div className="error">{msg}</div>}
            </ErrorMessage>
            {errors?.webhookUrlError && (
              <div className="error text-danger">
                {validationErrors.webhookUrlError}
              </div>
            )}
            <span className="text-muted font-0_75">
              The url where your WooCommerce store will receive payment
              notifications (Webhook URL).
              <br />
              <br />
              Example: https://mystore.com/?wc-api=wc-api=wo_banana_crystal
            </span>
          </div>
          <div className="form-group row required form-check">
            <Field
              type="checkbox"
              name="enable_subscriptions"
              className="form-check-input"
              id="subscriptions"
            />
            <label className="form-check-label" htmlFor="subscriptions">
              Enable Subscriptions
            </label>
            <ErrorMessage name="enable_subscriptions">
              {msg => <div className="error">{msg}</div>}
            </ErrorMessage>
          </div>
          { values.enable_subscriptions && (<div className="pt-2 pb-2">
            <div className="form-group row required">
              <label htmlFor="redirect_url" className="form-label">
                Subscriptions Completion or Thank You Page URL
              </label>
              <Field
                id="alternate_redirect_url"
                name="alternate_redirect_url"
                className="form-control font-0_75"
                validate={url}
                onChange={handleChange}
              />
              <ErrorMessage name="alternate_redirect_url">
                {msg => <div className="error">{msg}</div>}
              </ErrorMessage>
              {errors?.alternateRedirectUrlError && (
                <div className="error text-danger">
                  {validationErrors.alternateRedirectUrlError}
                </div>
              )}
              <span className="text-muted font-0_75">
                The subscriptions thank you page for your WooCommerce store where your
                customers will be redirected after they pay for the subscribtion
                (Subscription Redirect URL). Only required if you have subscriptions enabled.
                <br />
                <br />
                Example: https://mystore.com/my-account/bc-subscription/
              </span>
            </div>
          </div>) }

          <div className="form actions">
            <div className="row mx-auto">
              <div className="col-md-12 text-right pr-0 pt-4">
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={creating}
                >
                  {creating && (
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                  Save
                </button>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default WordPressForm;
