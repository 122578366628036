import React, { FC } from "react";
import MenuSelect from "./MenuSelect";

interface Props {
  countries: string[];
  currencies: string[];
  paymentMethods: string[];
  onSelectFilter: (attribute: string, value: string) => void;
  handleReload: () => void;
  filters: {
    paymentMethod: string;
    country: string;
    currency: string;
  };
}

const Filters: FC<Props> = ({
  countries,
  currencies,
  paymentMethods,
  filters,
  onSelectFilter,
  handleReload,
}) => (
  <div className="offer-filter-div d-flex justify-content-between">
    <div className="filters">
      <p className="filtericon-text">
        Filter by<i className="fas fa-filter"></i>: &nbsp;{" "}
      </p>
      <div className="filter-options">
        <MenuSelect
          attribute="Countries"
          allLabel="All Regions"
          iconClass="globe"
          items={countries}
          label="country"
          handleFilter={onSelectFilter}
          preferredValue={filters.country}
        />
        <MenuSelect
          attribute="Payment Methods"
          allLabel="All Payments"
          iconClass=""
          label="paymentMethod"
          items={paymentMethods}
          handleFilter={onSelectFilter}
          preferredValue={filters.paymentMethod}
        />
        <MenuSelect
          attribute="Currencies"
          allLabel="All Currencies"
          iconClass="money"
          label="currency"
          preferredValue={filters.currency}
          items={currencies}
          handleFilter={onSelectFilter}
        />
        <div
          className="refresh-offer-list-mobile d-sm-block d-md-none"
          onClick={handleReload}
        >
          {" "}
          <i
            className="fa fa-refresh p-2 font-weight-bold"
            title="Refresh Offers List"
          ></i>
          Refresh List
        </div>
      </div>
    </div>
    <div className="refresh-offer-list float-right">
      <button
        className="offer-refresh-btn d-md-block d-none"
        onClick={handleReload}
      >
        <i
          className="fa fa-refresh p-1 font-weight-bold"
          title="Refresh Offers List"
        ></i>
        Refresh List
      </button>
    </div>
  </div>
);

export default Filters;
