import React, { Component } from "react";
import _ from "lodash";
import "./index.scss";
import {
  getRequest,
  postRequest,
  deleteRequest,
} from "../../../../utils/httpRequest";
import AddAccountModal from "../../Offers/components/SelectTrade/components/BankAccount/AddAccountModal.jsx";
import Table from "./Table";
import BCSuccess from "../../Feedbacks/bcsuccess";
import DeleteModal from "../deleteModal.jsx";

class BankAccounts extends Component {
  constructor(props) {
    super(props);

    this.state = {
      countries: [],
      currencies: [],
      bankAccounts: [],
      isAccountModalOpen: false,
      accountType: "personal",
      bankName: "",
      accountName: "",
      customBankDetails: "",
      accountNumber: "",
      routingNumber: "",
      sortCode: "",
      swiftCode: "",
      clabe: "",
      selectedBankAccount: null,
      iban: "",
      countryOfResidence: "",
      interacEmail: "",
      state: "",
      city: "",
      zipCode: "",
      step: "one",
      address: "",
      bankAccountCountry: {label: props.currentUserCountry, value: props.currentUserCountryCode},
      bankAccountCurrency: {label: props.currentUserAltCurrency, value: props.currentUserAltCurrency},
      isSubmitting: false,
      shouldDelete: false,
      accountToDelete: "",
      apiErrors: {}, // To store API errors
    };
  }

  componentDidMount() {
    this.getBankAccounts();
    this.getCountries();
    this.getCurrencies();
  }

  getBankAccounts = () => {
    this.setState({ isLoadingBankAccounts: true });
    getRequest("/p2p_trades/bank_accounts").then((response) => {
      this.setState({
        bankAccounts: response,
        isLoadingBankAccounts: false,
      });
    });
  };

  getCountries = () => {
    this.setState({ isLoadingCountries: true });
    getRequest("/p2p_trades/countries").then((response) => {
      const countries = response.map((country) => ({
        label: country.name,
        value: country.code,
      }));

      this.setState({
        countries,
       // bankAccountCountry: countries[0],
        isLoadingCountries: false,
      });
    });
  };

  getCurrencies = () => {
    this.setState({ isLoadingCurrencies: true });
    getRequest("/p2p_trades/currencies").then((response) => {
      const currencies = response.map((currency) => ({
        label: `${currency.name} (${currency.code})`,
        value: currency.code,
        rateToUsd: currency.rateToUsd,
      }));

      this.setState({
        currencies,
        //bankAccountCurrency: currencies[0],
        isLoadingCurrencies: false,
      });
    });
  };

  showSuccess = () => {
    const bcsuccess = document.querySelector("#bankaccountspage-success-feedback");
    bcsuccess.style.display = 'block';
    setTimeout(() => {
      bcsuccess.style.display = 'none';
    }, 5000);
  }

  closeDeleteModal = () => {
    const deleteModal = document.querySelector('#bankaccountspage-delete-modal');
    deleteModal.style.display = "none" 
  }

  deleteAccount = (e, id) => {
    e.preventDefault();
    this.accountToDelete = id;
    const deleteModal = document.querySelector('#bankaccountspage-delete-modal');
    deleteModal.style.display = "flex" 
  }

  proceedToDeleteAccount = () => {
    const { bankAccounts } = this.state;
    if (this.shouldDelete) {
      deleteRequest(`/p2p_trades/bank_accounts/${this.accountToDelete}`).then(() => {
        _.remove(bankAccounts, (account) => account.id === this.accountToDelete);


        this.setState({
          updateBankAccounts: bankAccounts,
        })
      });
      this.showSuccess();
      this.setState({
        shouldDelete: false,
        accountToDelete: "",
      })
    }
  };

  handleShouldDelete = (e) => {
    e.preventDefault();
    this.shouldDelete = true;
    this.proceedToDeleteAccount();
    this.closeDeleteModal();
  }

  toggleOpenBankAccountModal = (e) => {
    e.preventDefault();

    this.setState({
      isAccountModalOpen: !this.state.isAccountModalOpen,
      bankName: "",
      accountName: "",
      customBankDetails: "",
      accountNumber: "",
      routingNumber: "",
      sortCode: "",
      swiftCode: "",
      clabe: "",
      selectedBankAccount: null,
      iban: "",
      countryOfResidence: "",
      state: "",
      city: "",
      zipCode: "",
      step: "one",
      address: "",
      interacEmail: "",
      apiErrors: {}
    });
  };

  updateBankAccounts = (newAccount) => {
    this.setState((prevState) => ({
      bankAccounts: [...prevState.bankAccounts, newAccount],
      selectedBankAccount: newAccount,
    }));
  };

  handleInputChange = (e, key) => {
    this.setState({ [key]: e.target.value });
  };

  handleCurrencyChange = (bankAccountCurrency) => {
    this.setState({ bankAccountCurrency });
  };

  handleCountryChange = (bankAccountCountry) => {
    this.setState({ bankAccountCountry });
  };

  handleBankNameChange = (bankName) => {
    this.setState({ bankName: bankName?.value });
  };

  handleStepChange = (e, step) => {
    e.preventDefault();

    this.setState({ step });
  };

  onSubmit = (e) => {
    e.preventDefault();
 
 
    this.setState({ isSubmitting: true, apiErrors: {} }); // Clear previous errors
 
 
    const { selectedPaymentMethod } = this.props;
 
 
    const {
      city,
      state,
      address,
      zipCode,
      bankAccountCountry,
      bankAccountCurrency,
      bankName,
      accountName,
      accountNumber,
      swiftCode,
      clabe,
      customBankDetails,
      countryOfResidence,
      routingNumber,
      iban,
      interacEmail,
    } = this.state;
 
 
    if (!accountName.trim().length > 0) {
      alert("Account name cannot be empty or consist only of spaces.");
      this.setState({ isSubmitting: false });
      return;
    }
 
 
    const params = {
      city,
      state,
      address,
      iban_number: iban,
      zip_code: zipCode,
      country: bankAccountCountry.value,
      currency: bankAccountCurrency.value,
      bank_name: bankName,
      account_name: accountName,
      account_number: iban ? "12345678909" : accountNumber,
      routing_number: routingNumber,
      interac_email: interacEmail,
      swift_code: swiftCode,
      clabe: clabe,
      custom_bank_details: customBankDetails,
      country_of_residence: countryOfResidence,
      is_international_transfer: false,
    };
 
 
    postRequest("/p2p_trades/bank_accounts", params)
      .then((response) => {
        this.updateBankAccounts(response.data);
        this.setState({
          isSubmitting: false,
          bankName: "",
          accountName: "",
          customBankDetails: "",
          accountNumber: "",
          routingNumber: "",
          sortCode: "",
          swiftCode: "",
          clabe: "",
          selectedBankAccount: null,
          iban: "",
          countryOfResidence: "",
          state: "",
          city: "",
          zipCode: "",
          interacEmail: "",
          step: "one",
          address: "",
          apiErrors: {},
        });
 
 
        this.toggleOpenBankAccountModal(e);
        this.showSuccess();
      })
      .catch((error) => {
        this.setState({ isSubmitting: false });
        if (error.data) {
          this.setState({ apiErrors: error.data.error });
        }
      });
  }; 

  render() {
    const {
      isAccountModalOpen,
      step,
      accountType,
      bankAccountCountry,
      bankAccountCurrency,
      bankName,
      accountName,
      customBankDetails,
      accountNumber,
      sortCode,
      swiftCode,
      clabe,
      iban,
      countryOfResidence,
      state,
      city,
      zipCode,
      bankAccounts,
      selectedBankAccount,
      address,
      countries,
      currencies,
      interacEmail,
      isLoadingBankAccounts,
      isSubmitting,
      accountToDelete,
      shouldDelete,
      apiErrors,
    } = this.state;

    return (
      <>
        <div id="bankaccountspage-success-feedback">
          <BCSuccess />
        </div>

        <div id="bankaccountspage-delete-modal">
          <DeleteModal 
            closeDeleteModal={this.closeDeleteModal}
            handleShouldDelete={this.handleShouldDelete}
          />
        </div>
       
        <div className="bankaccountspage-container">

          <div className="bankaccountspage-section">
            <small className="_page-title">{" "}</small>
            <div className="bankaccountspage-nav">
              {/* adding user guide and add account */}
              <div className="bankaccountspage_nav-item">
                {/* add bank acct */}
                <button
                  type="button"
                  className="bankaccountspage_nav-item-btn"
                  onClick={this.toggleOpenBankAccountModal}
                >
                  <svg
                    className="_icon"
                    width="19"
                    height="22"
                    viewBox="0 0 19 22"
                    fill="#F79237"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2.71429 0.5C1.21719 0.5 0 1.84531 0 3.5V18.5C0 20.1547 1.21719 21.5 2.71429 21.5H16.2857C17.7828 21.5 19 20.1547 19 18.5V3.5C19 1.84531 17.7828 0.5 16.2857 0.5H2.71429ZM8.48214 15.125V12.125H5.76786C5.20379 12.125 4.75 11.6234 4.75 11C4.75 10.3766 5.20379 9.875 5.76786 9.875H8.48214V6.875C8.48214 6.25156 8.93594 5.75 9.5 5.75C10.0641 5.75 10.5179 6.25156 10.5179 6.875V9.875H13.2321C13.7962 9.875 14.25 10.3766 14.25 11C14.25 11.6234 13.7962 12.125 13.2321 12.125H10.5179V15.125C10.5179 15.7484 10.0641 16.25 9.5 16.25C8.93594 16.25 8.48214 15.7484 8.48214 15.125Z"
                      fill="#F79237"
                    />
                  </svg>
                  {" "}
                  Add Bank Account
                </button>
              </div>
          
              {/* user guide */}
              <div className="bankaccountspage_nav-item">
                <button
                  type="button"
                  className="bankaccountspage_nav-item-btn"
                  onClick={() => window.open("https://www.bananacrystal.com/docs/add-bank-account/", "_blank")}
                  >
                  <svg
                    className="_icon"
                    width="20"
                    height="24"
                    viewBox="0 0 20 24"
                    fill="#F79237"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10 0C7.82292 0 5.96875 1.25156 5.28646 3H3.33333C1.49479 3 0 4.34531 0 6V21C0 22.6547 1.49479 24 3.33333 24H16.6667C18.5052 24 20 22.6547 20 21V6C20 4.34531 18.5052 3 16.6667 3H14.7135C14.0312 1.25156 12.1771 0 10 0ZM10 3C10.442 3 10.866 3.15804 11.1785 3.43934C11.4911 3.72064 11.6667 4.10218 11.6667 4.5C11.6667 4.89782 11.4911 5.27936 11.1785 5.56066C10.866 5.84196 10.442 6 10 6C9.55797 6 9.13405 5.84196 8.82149 5.56066C8.50893 5.27936 8.33333 4.89782 8.33333 4.5C8.33333 4.10218 8.50893 3.72064 8.82149 3.43934C9.13405 3.15804 9.55797 3 10 3ZM5.51042 10.7484C5.92187 9.70313 7.02604 9 8.26042 9H11.2969C13.1146 9 14.5833 10.3266 14.5833 11.9578C14.5833 13.0172 13.9531 13.9969 12.9323 14.5266L11.25 15.3937C11.2396 16.0031 10.6823 16.5 10 16.5C9.30729 16.5 8.75 15.9984 8.75 15.375V14.7422C8.75 14.3391 8.98958 13.9688 9.38021 13.7672L11.6875 12.5766C11.9323 12.45 12.0833 12.2156 12.0833 11.9625C12.0833 11.5687 11.7292 11.2547 11.2969 11.2547H8.26042C8.08333 11.2547 7.92708 11.3531 7.86979 11.5031L7.84896 11.5594C7.61979 12.1453 6.90104 12.45 6.25521 12.2438C5.60937 12.0375 5.26562 11.3906 5.49479 10.8094L5.51562 10.7531L5.51042 10.7484ZM8.33333 19.5C8.33333 19.1022 8.50893 18.7206 8.82149 18.4393C9.13405 18.158 9.55797 18 10 18C10.442 18 10.866 18.158 11.1785 18.4393C11.4911 18.7206 11.6667 19.1022 11.6667 19.5C11.6667 19.8978 11.4911 20.2794 11.1785 20.5607C10.866 20.842 10.442 21 10 21C9.55797 21 9.13405 20.842 8.82149 20.5607C8.50893 20.2794 8.33333 19.8978 8.33333 19.5Z"
                      fill="#F79237"
                    />
                  </svg>
                  {" "}
                  User Guide
                </button>
              </div>
            </div>
          </div>
          
          {/* table component */}
          <div className="bankaccounts_page-table">
            <Table
              bankAccounts={bankAccounts}
              // loading={isLoadingBankAccounts}
              deleteAccount={this.deleteAccount}
            />

            {isAccountModalOpen && (
              <AddAccountModal
                handleCloseModal={this.toggleOpenBankAccountModal}
                selectedBankAccount={selectedBankAccount}
                countries={countries}
                currencies={currencies}
                step={step}
                accountType={accountType}
                bankAccountCountry={bankAccountCountry}
                bankAccountCurrency={bankAccountCurrency}
                bankName={bankName}
                accountName={accountName}
                customBankDetails={customBankDetails}
                accountNumber={accountNumber}
                sortCode={sortCode}
                interacEmail={interacEmail}
                swiftCode={swiftCode}
                clabe={clabe}
                iban={iban}
                countryOfResidence={countryOfResidence}
                state={state}
                city={city}
                zipCode={zipCode}
                address={address}
                isSubmitting={isSubmitting}
                handleStepChange={this.handleStepChange}
                handleInputChange={this.handleInputChange}
                handleCountryChange={this.handleCountryChange}
                handleCurrencyChange={this.handleCurrencyChange}
                handleBankNameChange={this.handleBankNameChange}
                apiErrors={apiErrors}
                onSubmit={this.onSubmit}
              />
            )}
          </div>
        </div>
      </>
      );
    }
  }

export default BankAccounts;
