import React from "react";

const Preview = ({
  fees,
  amount,
  netAmount,
  selectedCurrency,
  firstTimeWithdrawalFee,
  isFirstTimeWithdrawal,
}) => (
  <div className="preview-wrapper mt-md-3">
    <div className="d-flex flex-column flex-md-row justify-content-md-around bg-gray rounded-lg p-3">
      <div className="d-flex flex-column align-items-md-center mb-1 mb-md-0 ">
        <label className="form-label font-weight-bold mb-1">
          Amount
          <a
            data-toggle="tooltip"
            className="ml-1"
            data-original-title="Subtotal amount (Crypto)."
          >
            <i className="fa fa-info-circle"></i>
          </a>
        </label>
        <p className="font-0_8 my-1 ml-0">
          {selectedCurrency}{" "}
          {parseFloat(amount).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </p>
      </div>

      <div className="d-flex flex-column align-items-md-center mb-1 mb-md-0 ">
        <label className="form-label font-weight-bold mb-1">
          Fee
          <a
            data-toggle="tooltip"
            className="ml-1"
            data-original-title="Transaction and processing fees (Crypto)"
          >
            <i className="fa fa-info-circle"></i>
          </a>
        </label>
        <p className="font-0_8 my-1 ml-0">
          {selectedCurrency}{" "}
          {parseFloat(fees).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </p>
      </div>

      {isFirstTimeWithdrawal && (
        <div className="d-flex flex-column align-items-md-center mb-1 mb-md-0 ">
          <label className="form-label font-weight-bold mb-1 pr-0">
            One Time Setup Fee
            <a
              data-toggle="tooltip"
              className="ml-1 pl-1 pr-0"
              data-original-title="One time transaction and processing fees (Crypto)"
            >
              <i className="fa fa-info-circle"></i>
            </a>
          </label>
          <p className="font-0_8 my-1 ml-0">
            {selectedCurrency}{" "}
            {parseFloat(firstTimeWithdrawalFee).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </p>
        </div>
      )}

      <div className="d-flex flex-column align-items-md-center mb-1 mb-md-0 ">
        <label className="form-label font-weight-bold mb-1">
          Total
          <a
            data-toggle="tooltip"
            className="ml-1"
            data-original-title="Total transaction amount (Crypto)"
          >
            <i className="fa fa-info-circle"></i>
          </a>
        </label>
        <p className="font-0_8 my-1 ml-0">
          {selectedCurrency}{" "}
          {parseFloat(netAmount).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </p>
      </div>
    </div>
  </div>
);

export default Preview;
