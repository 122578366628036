import React, { useState, useRef, useEffect } from "react";
import CurrencyFlag from "react-currency-flags";
import { postRequest } from "../../../utils/httpRequest";
import currencies from "../../SelectCurrency/currencies.json";

export default function CurrencySelect({ preferredCurrency }) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState({
    value: preferredCurrency,
  });
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState(
    currencies.sort((a, b) => a.label.localeCompare(b.label))
  );
  const dropdownRef = useRef(null);
  const searchInputRef = useRef(null);
  const buttonRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
    setSearchTerm("");
  };

  const handleSelect = (option) => {
    updateAlternateCurrency(option.value);
    setSelectedOption(option);
    setIsOpen(false);
    setSearchTerm("");
  };

  const updateAlternateCurrency = async (currency) => {
    const crsfToken = $('meta[name="csrf-token"]').attr("content");

    const data = {
      settings: {
        alternate_currency: currency,
      },
    };

    postRequest("/users/settings", data, {
      "X-CSRF-Token": crsfToken,
    })
      .then(() => {
        //refresh page
        window.location.reload();
      })
      .catch((error) => {
        // reset the currency back
        console.log(error);
      });
  };

  useEffect(() => {
    if (searchTerm === "") {
      setFilteredData(
        currencies.sort((a, b) => a.label.localeCompare(b.label))
      );
    } else {
      setFilteredData(
        currencies
          .filter((option) =>
            option.label.toLowerCase().includes(searchTerm.toLowerCase())
          )
          .sort((a, b) => a.label.localeCompare(b.label))
      );
    }
  }, [searchTerm]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (isOpen && searchInputRef.current) {
      searchInputRef.current.focus();
      let selectedCurrency = currencies.find(
        (currency) => currency.value === preferredCurrency
      );
      if (selectedCurrency) {
        setSelectedOption(selectedCurrency);
      }
    }
  }, [isOpen, preferredCurrency]);

  return (
    <div className="currency-select-container" ref={dropdownRef}>
      <button
        ref={buttonRef}
        className="currency-select-button"
        onClick={toggleDropdown}
        aria-haspopup="listbox"
        aria-expanded={isOpen}
        title="Select your alternate currency"
        type="button"
      >
        <CurrencyFlag currency={selectedOption?.value} size="sm" />{" "}
        {selectedOption?.value} <i className="fa fa-caret-down"></i>
      </button>
      {isOpen && (
        <div className="currency-select-dropdown">
          <input
            ref={searchInputRef}
            type="text"
            placeholder="Search..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="currency-select-search-input"
            aria-label="Search options"
          />
          {filteredData.length > 0 ? (
            <ul className="currency-select-ul" role="listbox">
              {filteredData.map((option) => (
                <li
                  key={option.label}
                  className={`currency-select-option`}
                  onClick={() => handleSelect(option)}
                  role="option"
                  aria-selected={selectedOption.value === option.value}
                >
                  <CurrencyFlag currency={option.value} size="sm" />{" "}
                  {option.label}
                </li>
              ))}
            </ul>
          ) : (
            <div className="currency-select-no-results">No results found</div>
          )}
        </div>
      )}
    </div>
  );
}
