import React from "react";
import UserOnlineStatus from "../../../../UserOnlineStatus";
import { calculateTotalAmount } from "../helpers";
import { TargetAmount, TradeLimits, TradeRate } from "../../../TradeIcons";
import { abbreviateNumber } from "../helpers";
import { getRating } from "../../../Offers/AllOffers/helpers";

const Rate = ({ offer, sellerRate, offerTypeDisplay, reviews }) => {

  const calculateMinUSD = () => {
    const tradeRate = Number(offer?.tradeRate);
    const rangeMin = Number(offer?.rangeMin);
  
    const result = tradeRate !== 0 ? rangeMin / tradeRate : 0;
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(result || 0);
  };
  
  const calculateMaxUSD = () => {
    const tradeRate = Number(offer?.tradeRate);
    const rangeMax = Number(offer?.rangeMax);
  
    const result = tradeRate !== 0 ? rangeMax / tradeRate : 0;
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(result || 0);
  };
  
  return (
    <div>
      <div className="createtrade-about-profile">
        <div>
          <div className="container">
            <div className="row">
              <div className="about-trader">
                <div className="about-traderprofile-section">
                  <div>
                    <small
                      className={`broker-details-heading ${offerTypeDisplay === 'sell' ? 'createtrade-sell' : 'createtrade-buy'}`}>
                      Broker Details
                    </small>
                    <div className="createtrade-about-profile-details">
                      <div className="createtrade-about-profile-name">
                        <p title={`${offer.customOfferLabel}`}>
                          <a href={`/profile/${offer.profile?.userName}`} className="createtrade-profilename" target="_blank" rel="noopener noreferrer">
                          <i className="fa fa-user-circle"></i>{" "}
                          {offer.customOfferLabel
                          ? `${offer.customOfferLabel.slice(0, 15)}${offer.customOfferLabel.length > 15 ? '...' : ''}`
                          : '...'}{" "}
                          </a>
                          <i title="Verified Broker" className="fa fa-check-circle" style={{ color: "#f79236" }}></i>
                        </p>
                      </div>
                      <div className="createtrade-online">
                        {offer.userId && (
                          <UserOnlineStatus
                            userId={offer.userId}
                            online={offer.online}
                            lastSeenAt={offer.lastSeenAt}
                          />
                        )}
                      </div>
                    </div> 
                  </div>

                  {/* Profile details */}
                  <div className="createtrade-profiledetails-section">
                    <div className="profile-more-details">
                      <>
                        <span title="Total trades completed">{abbreviateNumber(offer?.profile?.totalTrades || 0)} Trades</span>
                        <span className="offer-profiledetails-divider">&nbsp;|&nbsp;</span>
                        <span title="Rating on previous trades">
                        <i className="fa fa-thumbs-up profiledetails-icon"></i>&nbsp;
                        {getRating(offer?.profile?.id,reviews)}
                        <span className="offer-profiledetails-divider">&nbsp;|&nbsp;</span>

                       </span>
                        <span title="Trade must be completed within 30 mins">
                          <i className="fa fa-stopwatch profiledetails-icon"></i> 30mins
                        </span>
                      </>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div>
            <div>
              <div className="rate-and-limits-cont">
                <div className="rate-and-limits">
                  <div className="rate-and-limits-sections" title="Broker's Price per USD">
                    <span>
                      <TradeRate />
                    </span>
                    <h4 className="text-muted leading-normal font-1_3">
                      Trade
                    </h4>
                    <h4 className="text-muted leading-normal font-1_3">
                      Rate
                    </h4>
                    <span className="font-1_1 font-weight-bold">
                      {new Intl.NumberFormat("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }).format(
                        parseFloat(sellerRate || "0")
                      )}{" "}
                      {offer.fiatCurrency?.code}
                    </span>
                  </div>
                  
                  <div className="rate-and-limits-sections" title="Broker's Target Amount">
                    <span>
                      <TargetAmount />
                    </span>
                    <h4 className="text-muted leading-normal font-1_3">
                      Target
                    </h4>
                    <h4 className="text-muted leading-normal font-1_3">
                      Amount
                    </h4>
                    <span className="font-1_1 font-weight-bold">
                      {new Intl.NumberFormat("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }).format(
                        parseFloat(offer.availableAmount || "0")
                      )}{" "}
                      USD
                    </span>
                  </div>

                  <div className="rate-and-limits-sections" title="Broker's Minimum and Maximum Amount for Each Trade">
                    <span>
                      <TradeLimits />
                    </span>
                    <h4 className="text-muted leading-normal font-1_3 text-capitalize">
                      Trade Limits
                    </h4>
                    <p className="rate-and-limit-minmax">
                      <span>
                        <span className="rate-and-limit-minmax-title">
                          Min:{" "}
                        </span>
                        <span className="font-weight-bold">
                        {new Intl.NumberFormat('en-US', {
                          style: 'decimal',
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }).format(offer?.rangeMin || 0)}
                        </span>
                        <span className="font-weight-bold">
                          {" "}
                          {offer.fiatCurrency?.code}
                        </span>
                        <span>
                          {" "}
                          (
                            {calculateMinUSD() || "" }
                          )
                        </span>
                      </span>
                      <span>
                        <span className="rate-and-limit-minmax-title">
                          Max:{" "}
                        </span>
                        <span className="font-weight-bold">
                          {new Intl.NumberFormat('en-US', {
                            style: 'decimal',
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }).format(offer?.rangeMax || 0)}
                        </span>
                        <span className="font-weight-bold">
                          {" "}
                          {offer.fiatCurrency?.code}
                        </span>
                        <span>
                          {" "}
                          (
                            {calculateMaxUSD()}
                          )
                        </span>
                      </span>
                    </p>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Rate;
