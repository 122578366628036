import React, { useState } from "react";
import { CardType } from "../../Types";
import plasticBlack from "../../../../../../../assets/images/plastic_black.png";
import metalSilver from "../../../../../../../assets/images/metal_silver.png";
import metalGold from "../../../../../../../assets/images/metal_gold.png";
import metalBlack from "../../../../../../../assets/images/metal_black.png";
import CardDetail from "./CardDetail";
import Modal from "./Modal";

interface Props {
  availableBalance: number;
  isQAUser: boolean;
  cardTypes: CardType[];
  onSelectCardType: (e: any, cardType: string) => void;
}

const CardTypes: React.FC<Props> = ({
  availableBalance,
  isQAUser,
  cardTypes,
  onSelectCardType,
}) => {
  const [modalContent, setModalContent] = useState<string | null>(null);

  const onClickCardType = (e: any, cardType: CardType) => {
    e.preventDefault();

    if (!isQAUser && cardType.application_fee > availableBalance) {
      alert("You have insufficient balance to apply for this card");
      return;
    }

    window.scrollTo(0, 0);
    onSelectCardType(e, cardType.type);
  };

  const cardTypeImages: { [key: string]: string } = {
    Plastic: `${plasticBlack}`,
    "Metal Silver": `${metalSilver}`,
    "Metal Gold": `${metalGold}`,
    "Metal Black": `${metalBlack}`,
  };

  const handleTooltipClick = (tooltipText: string) => {
    setModalContent(tooltipText);
  };

  const closeModal = () => {
    setModalContent(null);
  };

  return (
    <>
      <div className="header">
        <div className="nav-steps d-none d-sm-flex">
          <span>Choose Your Card Type</span>
        </div>
      </div>
      <div>
        <div className="cards-section">
          {cardTypes.map((cardType, i) => (
            <div
              key={i}
              className="cards-container"
              style={{ backgroundColor: "#f0eef9" }}
            >
              <div className="card-main-details text-center">
                <img
                  src={cardTypeImages[cardType.name]}
                  alt="BC Debit Card"
                  className="card-img"
                />
                <div className="card-name mt-2">{cardType.name}</div>
                <div className="card-fee">
                  {cardType.discounted_fee ? (
                    <>${cardType.discounted_fee}</>
                  ) : (
                    <>${cardType.application_fee} </>
                  )}
                  <i
                    className="fa fa-info-circle card-fee-info"
                    aria-hidden="true"
                    title="The total cost of card application, processing, and shipping to any location worldwide."
                    onClick={(e) =>
                      handleTooltipClick(
                        e.currentTarget.getAttribute("title") || ""
                      )
                    }
                  ></i>
                  {cardType.coupon_code && (
                    <p style={{ fontSize: "14px", margin: "0" }}>
                      Coupon: {cardType.coupon_code}
                    </p>
                  )}
                </div>
              </div>

              <CardDetail
                cardType={cardType}
                onClickCardType={onClickCardType}
                handleTooltipClick={handleTooltipClick}
              />
            </div>
          ))}
        </div>
      </div>

      {modalContent && (
        <Modal modalContent={modalContent} closeModal={closeModal} />
      )}
    </>
  );
};

export default CardTypes;
