import React, { Component } from "react";
import { Formik, Form, Field } from "formik";
import { getRequest, postRequest } from "../../utils/httpRequest";
import { formErrors } from "../../utils/formErrors";
import { formattedApiErrors, validateAddress } from "./helper";
import { required } from "../../utils/formValidations";
import "./style.scss";

class ContactWalletAddress extends Component {
  constructor(props) {
    super(props);

    this.state = {
      apiErrors: {},
      coins: props.coins,
      submitting: false
    };
  }

  handleBlockChainChange = (e, setFieldValue, values) => {
    e.persist();
    const blockchain = e.target.value;

    setFieldValue("blockchain", blockchain);

    this.getCoinsForBlockchain(blockchain, setFieldValue);
  };

  handleAddressChange = (e, setFieldValue, values) => {
    e.persist();

    const value = e.target.value.split(":");

    const address = value[0];
    const tag = value[1];

    setFieldValue("address", address);
    setFieldValue("tag", tag);
  };

  validateBlockchainAddress = (values, address) => {
    let error;
    if (!address) {
      error = "Required";
    } else if (!validateAddress(values.blockchain, address)) {
      error = "Invalid Address";
    }
    return error;
  };

  getCoinsForBlockchain = (blockchain, setFieldValue) => {
    getRequest(
      `/contact_wallet_addresses/new.json?blockchain=${blockchain}`
    ).then(response => {
      const { coins } = response;

      setFieldValue("coin", coins[0]?.value);

      this.setState({
        coins
      });
    });
  };

  submit = values => {
    this.setState({ submitting: true });

    const {
      coin,
      blockchain,
      walletLabel,
      address,
      tag,
      addressVerified
    } = values;

    const data = {
      contact_wallet_address: {
        tag,
        coin,
        address,
        chain: blockchain,
        wallet_label: walletLabel,
        address_verified: addressVerified
      }
    };

    const crsfToken = $('meta[name="csrf-token"]').attr("content");

    postRequest(`/contact_wallet_addresses.json`, data, {
      "X-CSRF-Token": crsfToken
    })
      .then(response => {
        window.location = "/contact_wallet_addresses";
      })
      .catch(error => {
        const errors = formErrors(error.data.errors);

        this.setState({
          apiErrors: formattedApiErrors(errors)
        });

        this.setState({ submitting: false });
      });
  };

  render() {
    const { step, apiErrors, coins, submitting } = this.state;
    const { blockchains } = this.props;

    return (
      <div>
        <Formik
          initialValues={{
            coin: coins[0]?.value,
            blockchain: blockchains[0]?.value,
            walletLabel: "",
            address: "",
            tag: "",
            addressVerified: false
          }}
          onSubmit={values => this.submit(values)}
        >
          {({ errors, values, handleChange, setFieldValue, validateForm }) => (
            <Form className="form form-horizontal">
              <div className="row">
                <div className="col-6">
                  <div className="form-group required">
                    <label htmlFor="blockchain" className="form-label">
                      Blockchain
                    </label>
                    <Field
                      as="select"
                      id="blockchain"
                      name="blockchain"
                      className={`form-control mt-1 rounded border-gray ${errors.blockchain || apiErrors?.blockChainError ? 'border-danger' : ''}`}
                      validate={required}
                      onChange={e =>
                        this.handleBlockChainChange(e, setFieldValue, values)
                      }
                    >
                      {blockchains.map(blockchain => (
                        <option key={blockchain.value} value={blockchain.value}>
                          {blockchain.label} ({blockchain.value})
                        </option>
                      ))}
                    </Field>
                    {errors.blockchain && (
                      <div className="error text-danger">{errors.blockchain}</div>
                    )}
                    {apiErrors?.blockChainError && (
                      <div className="error text-danger">{apiErrors.blockChainError}</div>
                    )}
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group required">
                    <label htmlFor="coin" className="form-label">
                      Coin
                    </label>
                    <Field
                      id="coin"
                      name="coin"
                      as="select"
                      className={`form-control mt-1 rounded border-gray ${errors.coin || apiErrors?.coinError ? 'border-danger' : ''}`}
                      validate={required}
                      onChange={handleChange}
                    >
                      {coins.map(coin => (
                        <option key={coin.value} value={coin.value}>
                          {coin.value}
                        </option>
                      ))}
                    </Field>
                    {errors.coin && <div className="error text-danger">{errors.coin}</div>}
                    {apiErrors?.coinError && (
                      <div className="error text-danger">{apiErrors.coinError}</div>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-6">
                  <div className="form-group required">
                    <label htmlFor="walletLabel" className="form-label">
                      Wallet Label
                    </label>
                    <Field
                      id="walletLabel"
                      name="walletLabel"
                      validate={required}
                      className={`form-control mt-1 rounded border-gray ${errors.walletLabel || apiErrors?.walletLabelError ? 'border-danger' : ''}`}
                      onChange={handleChange}
                    />
                    {errors.walletLabel && (
                      <div className="error text-danger">{errors.walletLabel}</div>
                    )}
                    {apiErrors?.walletLabelError && (
                      <div className="error text-danger">{apiErrors.walletLabelError}</div>
                    )}
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group required">
                    <label htmlFor="address" className="form-label">
                      Address
                    </label>
                    <Field
                      id="address"
                      name="address"
                      validate={value =>
                        this.validateBlockchainAddress(values, value)
                      }
                      className={`form-control mt-1 rounded border-gray ${errors.address || apiErrors?.addressError ? 'border-danger' : ''}`}
                      onChange={e =>
                        this.handleAddressChange(e, setFieldValue, values)
                      }
                    />
                    {errors.address && (
                      <div className="error text-danger">{errors.address}</div>
                    )}
                    {apiErrors?.addressError && (
                      <div className="error text-danger">{apiErrors.addressError}</div>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="form-group">
                    <label htmlFor="tag" className="form-label">
                      Tag
                    </label>
                    <Field
                      id="tag"
                      name="tag"
                      validate={values.coin === "XLM" ? required : ""}
                      className={`form-control mt-1 rounded border-gray ${errors.tag || apiErrors?.tagError ? 'border-danger' : ''}`}
                      onChange={handleChange}
                    />
                    {errors.tag && <div className="error text-danger">{errors.tag}</div>}
                    {apiErrors?.tagError && (
                      <div className="error text-danger">{apiErrors.tagError}</div>
                    )}
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-group form-check">
                    <div className="custom-control custom-checkbox m-0 mr-auto">
                      <Field
                        type="checkbox"
                        name="addressVerified"
                        className={`custom-control-input ${errors.addressVerified || apiErrors?.addressVerifiedError ? 'is-invalid' : ''}`}
                        id="addressVerified"
                      />
                      <label className="custom-control-label font-0_85 text-muted" htmlFor="addressVerified">
                        I have verified that this is the correct address for the
                        selected coin and understand that sending cryptocurrencies
                        to the incorrect address can result in the permanent loss of
                        my funds
                      </label>
                      {errors.addressVerified && (
                        <div className="error text-danger">{errors.addressVerified}</div>
                      )}
                      {apiErrors?.addressVerifiedError && (
                        <div className="error text-danger">
                          {apiErrors.addressVerifiedError}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="action">
                <button
                  type="submit"
                  className="btn btn-primary btn-purple w-100"
                  disabled={submitting}
                >
                  {submitting && (
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                  Submit
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}

export default ContactWalletAddress;