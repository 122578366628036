import React, { useState } from "react";
import { Field, FormikHelpers, ErrorMessage } from "formik";
import { required } from "../../utils/formValidations";

const StepThree = ({
  submitting,
  errors,
  apiErrors,
  values,
  validateForm,
  handleChange,
  handlePreviousStepChange
}) => (
  <>
    <div className="form-group row">
      <label htmlFor="shoppingCartSoftwares" className="form-label">
        Which Shopping Cart Software Do you Use?
      </label>
      <div role="group" aria-labelledby="checkbox-group">
        <div className="form-group form-check">
          <Field
            type="checkbox"
            name="shoppingCartSoftwares"
            value="Shopify"
            className="form-check-input"
            id="shopify"
          />
          <label className="form-check-label" htmlFor="shopify">
            Shopify
          </label>
        </div>

        <div className="form-group form-check">
          <Field
            type="checkbox"
            value="Woocommerce"
            name="shoppingCartSoftwares"
            className="form-check-input"
            id="woocommerce"
          />
          <label className="form-check-label" htmlFor="woocommerce">
            Woocommerce
          </label>
        </div>

        <div className="form-group form-check">
          <Field
            type="checkbox"
            value="Instacart"
            name="shoppingCartSoftwares"
            className="form-check-input"
            id="instacart"
          />
          <label className="form-check-label" htmlFor="instacart">
            Instacart
          </label>
        </div>

        <div className="form-group form-check">
          <Field
            type="checkbox"
            value="Wordpress"
            name="shoppingCartSoftwares"
            className="form-check-input"
            id="wordpress"
          />
          <label className="form-check-label" htmlFor="wordpress">
            Wordpress
          </label>
        </div>

        <div className="form-group form-check">
          <Field
            type="checkbox"
            value="None"
            name="shoppingCartSoftwares"
            className="form-check-input"
            id="none"
          />
          <label className="form-check-label" htmlFor="none">
            None
          </label>
        </div>
        <div className="form-group form-check">
          <Field
            type="checkbox"
            value="Other"
            name="shoppingCartSoftwares"
            className="form-check-input"
            id="other"
          />
          <label className="form-check-label" htmlFor="other">
            Other
          </label>
        </div>
        {values.shoppingCartSoftwares.includes("Other") && (
          <Field
            id="otherSoftware"
            name="otherSoftware"
            className="form-control"
            validate={required}
            onChange={handleChange}
          />
        )}
      </div>
    </div>
    <div className="form-group row">
      <label htmlFor="instructions" className="form-label">
        Special Instructions or Comments
      </label>

      <Field
        as="textarea"
        id="instructions"
        name="instructions"
        className="form-control"
        onChange={handleChange}
      />
      {apiErrors?.instructionsError && (
        <div className="error text-danger">{apiErrors.instructionsError}</div>
      )}
    </div>
    <div className="form-group row required form-check">
      <Field
        type="checkbox"
        name="enableNotifications"
        className="form-check-input"
        id="notifications"
      />
      <label className="form-check-label" htmlFor="notifications">
        Receive partner emails
      </label>
      <ErrorMessage name="enable_subscriptions">
        {msg => <div className="error">{msg}</div>}
      </ErrorMessage>
    </div>

    <div className="action">
      <button
        type="button"
        className="btn btn-primary left"
        onClick={() => handlePreviousStepChange("two")}
      >
        Back
      </button>
      <button
        type="submit"
        className="btn btn-primary right"
        disabled={submitting}
      >
        {submitting && (
          <span
            className="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
        )}
        Submit
      </button>
    </div>
  </>
);

export default StepThree;
