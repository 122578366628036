import React, { useState } from "react";
import {
  Formik,
  Form,
  Field,
  FormikHelpers,
  FieldArray,
  ErrorMessage
} from "formik";
import { required } from "./validations";
import { postRequest, putRequest } from "../../utils/httpRequest";
import { formErrors } from "../../utils/formErrors";

const InstacartForm = ({ store_id, integration }) => {
  const [creating, setCreating] = useState(false);
  const [errors, setErrors] = useState({});

  const formattedErrors = errors => {
    const { validationErrors } = this.state;

    const clientIdError = errors["client_id"];
    const clientSecretError = errors["client_secret"];
    const grantTypeError = errors["grant_type"];

    validationErrors.clientIdError = clientIdError;
    validationErrors.clientSecretError = clientSecretError;
    validationErrors.grantTypeError = grantTypeError;

    return validationErrors;
  };

  const submit = values => {
    console.log(integration);
    const crsfToken = $('meta[name="csrf-token"]').attr("content");

    const data = {
      store_integration: values
    };
    setCreating(true);

    if (integration) {
      putRequest(`/stores/${store_id}/integrations/${integration?.id}`, data, {
        "X-CSRF-Token": crsfToken
      })
        .then(response => {
          window.location = `/stores/${store_id}/integrations`;
        })
        .catch(error => {
          const errors = formErrors(error.data.errors);

          setErrors(error);
          setCreating(false);
        });
    } else {
      postRequest(`/stores/${store_id}/integrations`, data, {
        "X-CSRF-Token": crsfToken
      })
        .then(response => {
          window.location = `/stores/${store_id}/integrations`;
        })
        .catch(error => {
          const errors = formErrors(error.data.errors);

          setErrors(error);
          setCreating(false);
        });
    }
  };

  return (
    <Formik
      initialValues={{
        client_id: integration?.client_id || "",
        client_secret: integration?.client_secret || "",
        grant_type: integration?.grant_type || "",
        scope: integration?.scope || "",
        code: integration?.code || "",
        assertion: integration?.assertion || "",
        redirect_uri: integration?.redirect_uri || "",
        integration_type: "Instacart"
      }}
      onSubmit={values => submit(values)}
    >
      {({ values, touched, handleChange }) => (
        <Form className="form">
          <div className="form-group row required">
            <label htmlFor="client_id" className="form-label">
              Client ID
            </label>
            <Field
              id="client_id"
              name="client_id"
              className="form-control"
              validate={required}
              onChange={handleChange}
            />
            <ErrorMessage name="client_id">
              {msg => <div className="error">{msg}</div>}
            </ErrorMessage>
            {errors?.clientIdError && (
              <div className="error text-danger">
                {validationErrors.clientIdError}
              </div>
            )}
          </div>
          <div className="form-group row required">
            <label htmlFor="client_secret" className="form-label">
              Client Secret
            </label>
            <Field
              type="password"
              id="client_secret"
              name="client_secret"
              className="form-control"
              validate={required}
              onChange={handleChange}
            />
            <ErrorMessage name="client_secret">
              {msg => <div className="error">{msg}</div>}
            </ErrorMessage>
            {errors?.clientSecretError && (
              <div className="error text-danger">
                {validationErrors.clientSecretError}
              </div>
            )}
          </div>
          <div className="form-group row required">
            <label htmlFor="grant_type" className="form-label">
              Grant Type
            </label>
            <Field
              id="grant_type"
              name="grant_type"
              className="form-control"
              validate={required}
              onChange={handleChange}
            />
            <ErrorMessage name="grant_type">
              {msg => <div className="error">{msg}</div>}
            </ErrorMessage>
          </div>
          {errors?.grantTypeError && (
            <div className="error text-danger">
              {validationErrors.grantTypeError}
            </div>
          )}
          <div className="form-group row">
            <label htmlFor="grant_type" className="form-label">
              Scope
            </label>
            <Field
              id="scope"
              name="scope"
              className="form-control"
              onChange={handleChange}
            />
            <ErrorMessage name="scope">
              {msg => <div className="error">{msg}</div>}
            </ErrorMessage>
          </div>
          <div className="form-group row">
            <label htmlFor="code" className="form-label">
              Authorization Code
            </label>
            <Field
              id="code"
              name="code"
              className="form-control"
              onChange={handleChange}
            />
            <ErrorMessage name="code">
              {msg => <div className="error">{msg}</div>}
            </ErrorMessage>
          </div>
          <div className="form-group row">
            <label htmlFor="assertion" className="form-label">
              Assertion
            </label>
            <Field
              id="assertion"
              name="assertion"
              className="form-control"
              onChange={handleChange}
            />
            <ErrorMessage name="assertion">
              {msg => <div className="error">{msg}</div>}
            </ErrorMessage>
          </div>
          <div className="form-group row">
            <label htmlFor="redirect_uri" className="form-label">
              Redirect Uri
            </label>
            <Field
              id="redirect_uri"
              name="redirect_uri"
              className="form-control"
              onChange={handleChange}
            />
            <ErrorMessage name="redirect_uri">
              {msg => <div className="error">{msg}</div>}
            </ErrorMessage>
          </div>
          <div className="action">
            <button
              type="submit"
              className="btn btn-primary left"
              disabled={creating}
            >
              {creating && (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              )}
              Submit
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default InstacartForm;
