import React, { Component } from "react";
import { Formik, Form } from "formik";
import toastr from "toastr";
import CardTypes from "./components/CardTypes";
import Header from "./components/Header";
import PersonalDetails from "./components/PersonalDetails";
import IdentificationDetails from "./components/IdentificationDetails";
import MailingDetails from "./components/MailingDetails";
import Preview from "./components/Preview";
import { postRequest } from "../../../../utils/httpRequest";
import { formErrors } from "../../../../utils/formErrors";
import {
  cardRequestParams,
  formattedApiErrors,
  stepFromError,
} from "./helpers";
import { CardType } from "./Types";
import "./index.scss";

interface Props {
  params: {
    availableBalance: number;
    cardTypes: CardType[];
    couponCode: string;
    user: {
      id: string;
      firstName: string;
      lastName: string;
      isQAUser: boolean;
    };
  };
}

interface State {
  step: "cardType" | "personal" | "identification" | "mailing" | "preview";
  cardType: string;
  cardTypes: CardType[];
  couponCode: string;
  submitting: boolean;
  isCouponClaimable: boolean;
  apiErrors?: any;
}

class New extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      step: "cardType",
      cardType: "plastic",
      couponCode: props.params?.couponCode,
      submitting: false,
      cardTypes: props.params?.cardTypes,
      isCouponClaimable: false,
      apiErrors: {},
    };
  }

  componentDidMount() {
    const { couponCode } = this.state;

    if (couponCode) {
      return this.getCouponClaimable();
    }
  }

  getCouponClaimable = () => {
    const { couponCode, cardTypes } = this.state;

    postRequest("/api/v1/cards/coupon_claimabilities", {
      coupon_code: couponCode,
    })
      .then((response) => {
        const updatedCardTypes = cardTypes.map((card) => {
          console.log("Checking card type:", card.type);
          return card.type === response.card_type
            ? {
                ...card,
                discounted_fee: response.discounted_fee,
                coupon_code: couponCode,
              }
            : card;
        });

        this.setState({ isCouponClaimable: true, cardTypes: updatedCardTypes });
      })
      .catch((error) => {
        console.error("Error fetching coupon:", error);
      });
  };

  handlePreviousStepChange = (step) => {
    this.setState({ step });
  };

  handleSetStep = (step) => {
    this.setState({ step });
  };
  handleNextStepChange = (validateForm, step) => {
    validateForm().then((errors) => {
      if (Object.keys(errors).length === 0) {
        window.scrollTo(0, 0);
        this.setState({ step });
      }
    });
  };

  onSelectCardType = (e, cardType) => {
    e.preventDefault();

    this.setState({ cardType });

    this.handleSetStep("personal");
  };

  confirmSubmission = (values) => {
    if (
      !values.passport_bio_page ||
      !values.passport_with_selfie ||
      !values.digital_signature
    ) {
      this.handleSetStep("identification");
      toastr.error("Please upload all required documents before proceeding.");
    } else {
      this.submit(values);
    }
  };

  submit = (values) => {
    const { cardType, isCouponClaimable, couponCode } = this.state;
    this.setState({ submitting: true });

    const params = cardRequestParams(cardType, values);

    const formData = new FormData();
    if (isCouponClaimable && couponCode) {
      formData.append("coupon_code", couponCode);
    }

    Object.entries(params).forEach(([key, value]) => {
      formData.append(`card_account[${key}]`, value);
    });

    postRequest("/api/v1/cards/users.json", formData, {
      "Content-Type": "multipart/form-data",
    })
      .then((response) => {
        window.location.href = `/cards/requests/${response.card_request_id}`;
      })
      .catch((error) => {
        console.log(error);
        this.setState({ submitting: false });

        if (error.data?.errors) {
          const errors = formErrors(error.data.errors);

          this.setState({
            apiErrors: formattedApiErrors(errors),
            step: stepFromError(error.data.errors),
          });
        }

        toastr.error(
          error?.error || error?.data?.error || "Something went wrong"
        );
      });
  };

  render() {
    const {
      params: { user, availableBalance },
    } = this.props;
    const { step, cardTypes, cardType, submitting, apiErrors } = this.state;

    return (
      <div className="w-100">
        <div className="create-offer-title">
          <h2 className="_page-title font-1_8 pl-1">Apply for a Card</h2>
        </div>
        <Formik
          initialValues={{
            first_name: user.firstName,
            last_name: user.lastName,
            name_on_card: `${user.firstName} ${user.lastName}`,
            salutation: "",
            occupation: "",
            gender: "male",
            phone_code: "",
            phone_number: "",
            passport_number: "",
            place_of_birth: "",
            passport_issued_date: "",
            passport_expiry_date: "",
            address: "",
            city: "",
            state: "",
            country: "",
            postal_code: "",
            passport_bio_page: "",
            passport_with_selfie: "",
            digital_signature: "",
          }}
          onSubmit={(values) => this.confirmSubmission(values)}
        >
          {({ setFieldValue, errors, values, handleChange, validateForm }) => (
            <Form className="form">
              {step === "cardType" && (
                <CardTypes
                  availableBalance={availableBalance}
                  cardTypes={cardTypes}
                  isQAUser={user.isQAUser}
                  onSelectCardType={this.onSelectCardType}
                />
              )}

              <div className="steps card shadow-sm rounded-lg">
                {step !== "cardType" && (
                  <div
                    className="col-md-12 mb-10"
                    style={{ marginBottom: "20px" }}
                  >
                    <Header step={step} handleSetStep={this.handleSetStep} />
                  </div>
                )}
                {step === "personal" && (
                  <PersonalDetails
                    errors={errors}
                    apiErrors={apiErrors}
                    validateForm={validateForm as any}
                    handleNextStepChange={this.handleNextStepChange}
                    handlePreviousStepChange={this.handlePreviousStepChange}
                  />
                )}
                {step === "identification" && (
                  <IdentificationDetails
                    errors={errors}
                    apiErrors={apiErrors}
                    validateForm={validateForm as any}
                    handleNextStepChange={this.handleNextStepChange}
                    handlePreviousStepChange={this.handlePreviousStepChange}
                  />
                )}
                {step === "mailing" && (
                  <MailingDetails
                    errors={errors}
                    apiErrors={apiErrors}
                    setFieldValue={setFieldValue}
                    selectedCountry={values.country}
                    validateForm={validateForm as any}
                    handleNextStepChange={this.handleNextStepChange}
                    handlePreviousStepChange={this.handlePreviousStepChange}
                  />
                )}
                {step === "preview" && (
                  <Preview
                    data={values}
                    handleSetStep={this.handleSetStep}
                    cardType={cardType}
                    cardTypes={cardTypes}
                    submitting={submitting}
                  />
                )}
              </div>
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}

export default New;
