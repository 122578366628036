import Filter from "bad-words";
import Decimal from "decimal.js";

export const validateString = (string) => {
  const filter = new Filter();

  const linkRegex = /(?:https?:\/\/)?(?:www\.)?\S+\.\S+/gi;
  const hasLink = linkRegex.test(string);

  if (hasLink) {
    return "cannot contain links";
  }
};

export const calcfixedPrice = (tradeType, selectedCurrency) => {
    let temFixedPrice;
    if (tradeType === "buy") {
      temFixedPrice =
        selectedCurrency.buyRate != null && !isNaN(selectedCurrency.buyRate) 
          ? selectedCurrency.buyRate
          : selectedCurrency.rateToUsd;
    } else if (tradeType === "sell") {
      temFixedPrice =
        selectedCurrency.sellRate != null && !isNaN(selectedCurrency.sellRate)
          ? selectedCurrency.sellRate
          : selectedCurrency.rateToUsd;
    }
    return temFixedPrice
}

export const formatNumber = (number) => {
  const options = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };

  const formatter = new Intl.NumberFormat("en-US", options);
  return number ? formatter.format(parseFloat(number)) : "0.00";
};

export const handleAmountChange = (e, setRange, setDisplayRate) => {
  let rawValue = e.target.value.replace(/[^0-9]/g, "");
  let [integerPart, decimalPart] = rawValue.split(".");

  integerPart = new Intl.NumberFormat("en-US", {
    maximumFractionDigits: 0,
  }).format(Number(integerPart));

  let formattedValue = decimalPart !== undefined ? `${integerPart}.${decimalPart}` : integerPart;

  setDisplayRate(formattedValue);
  setRange(formattedValue.replace(/,/g, ""));
};

export const getTotalAmount = (amount, actualRate, accountBalance) => {
  const amountValue = amount ? parseFloat(amount).toFixed(2) : 0;

  return amount
    ? parseFloat(amountValue) * actualRate
    : accountBalance * actualRate;
};

export function validateAmount(
  isMin,
  amount,
  tradeType,
  accountBalance,
  actualRate,
  rangeMin,
  rangeMax,
  setMinAmountError,
  setMaxAmountError,
  setRangeMin,
  setRangeMax,
  updateParent,
  currency
) {
  const minNumericValue = Number(rangeMin.replace(/,/g, ""));
  const maxNumericValue = Number(rangeMax.replace(/,/g, ""));
  const totalAmount = getTotalAmount(amount, actualRate, accountBalance);

  let error = "";
  let exceedBalanceCondition =
    tradeType === "sell" &&
    (minNumericValue > accountBalance * actualRate ||
      maxNumericValue > totalAmount);
  let fieldName = isMin ? "rangeMin" : "rangeMax";
  let otherNumericValue = isMin ? maxNumericValue : minNumericValue;
  let setError = isMin ? setMinAmountError : setMaxAmountError;
  let setRange = isMin ? setRangeMin : setRangeMax;

  if (!isNaN(otherNumericValue)) {
    if (isMin && minNumericValue > otherNumericValue) {
      error = "Minimum amount cannot be greater than the maximum amount.";
    } else if (!isMin && maxNumericValue < minNumericValue) {
      error = "Maximum amount cannot be less than the minimum amount.";
    }
  }

  // Check if min and max are in a valid range
  if (minNumericValue > maxNumericValue) {
    if (isMin) {
      error = "Minimum amount cannot be greater than the maximum amount.";
    } else {
      error = "Maximum amount cannot be less than the minimum amount.";
    }
  } else {
    setMinAmountError("");
    setMaxAmountError("");
  }

  if (exceedBalanceCondition) {
    error = `${
      isMin ? "Minimum" : "Maximum"
    } amount cannot exceed your total amount in ${currency?.value}.`;
    
    updateParent("amountValError", 1);
  } else {
    updateParent("amountValError", 0);
    setError(error);
  }

  setError(error);
  updateParent("rangeMin", rangeMin);
  updateParent("rangeMax", rangeMax);
}

export const validateTotalAmount = (
  amount,
  accountBalance,
  tradeType,
  tradeFee
) => {
  if (!amount) return "Amount is required.";

  const formattedAmount = parseFloat(amount.replace(/,/g, ""));
  const formattedAccountBalance = parseFloat(accountBalance);
  const fee = calculateTradeFee(formattedAmount, tradeFee);

  if (formattedAmount < 0.5) return "Minimum amount is USD 0.5";

  if (
    tradeType === "sell" &&
    formattedAmount + parseFloat(fee) > formattedAccountBalance
  ) {
    return "Amount plus fee cannot exceed your account balance.";
  }

  return "";
};

export const calculateTradeFee = (amount, fee) => {
  if (!amount || !fee) return 0;

  return ((fee / 100) * amount).toFixed(2);
};

export const calculateTotalAmount = (amount, tradeRate) => {
  return parseFloat(amount || "0") * parseFloat(tradeRate || "0");
};

export const getFiatAmount = (marketrate, amount) => {
  const marketRateVal = parseFloat(marketrate);
  const amountInUsd = parseFloat(amount);
  const calculatedMargin = marketRateVal * amountInUsd;
  return calculatedMargin.toFixed(2);
};

export const calculateTraderRate = (newMargin, currActualRate) => {
  const marginVal = newMargin ? new Decimal(Number(newMargin)) : 0;
  const rate = new Decimal(currActualRate);

  const tempAmount = rate.mul(marginVal).div(new Decimal(100));
  let result = rate.plus(tempAmount);
  let roundedResult = Number(result).toFixed(2);

  if (Math.abs(Number(roundedResult) - Math.floor(Number(roundedResult))) === 0.99) {
    let steppedResult = Number(roundedResult) + 0.01;
    return steppedResult.toFixed(2);
  }
  return roundedResult;
};

export const formatMinMaxAmount = (amount) => {
  const numericValue = amount.toString();
  return numericValue
    ? new Intl.NumberFormat("en-US", {
      style: "decimal",
      maximumFractionDigits: 2,
    }).format(numericValue)
    : "";
};
