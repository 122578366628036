import React, { Component } from "react";
import PayButtonForm from "./PayButtonForm";
import WooCommerceApiForm from "./WooCommerceApiForm";
import WooCommercePluginForm from "./WooCommercePluginForm";
import ShopifyForm from "./ShopifyForm";
import InstacartForm from "./InstacartForm";
import CryptoRewardsForm from "./CryptoRewardsForm";
import "./index.scss";

class StoreIntegrations extends Component {
  constructor(props) {
    super(props);

    this.state = {
      type: props.params.type
    };
  }

  componentDidMount() {}

  selectType = e => {
    this.setState({ type: event.target.value });
  };

  render() {
    const { type } = this.state;

    const {
      params: { store_id, integration, tokens, selectedTokens }
    } = this.props;

    return (
      <div>
        <form className="form">
          <div className="form-group row required">
            <label htmlFor="type" className="form-label">
              Integration Type
            </label>
            <select
              className="custom-select form-control"
              onChange={this.selectType}
              value={this.state.type}
              disabled={!!integration}
            >
              <option value="PayButton">Pay Button</option>
              <option value="WooCommerce Plugin">WooCommerce Plugin</option>
              <option value="CryptoRewards">Crypto Rewards</option>
            </select>
          </div>
        </form>
        {type === "PayButton" && (
          <PayButtonForm store_id={store_id} integration={integration} />
        )}
        {type === "WooCommerce Plugin" && (
          <WooCommercePluginForm
            store_id={store_id}
            integration={integration}
          />
        )}
        {type === "CryptoRewards" && (
          <CryptoRewardsForm
            store_id={store_id}
            integration={integration}
            allTokens={tokens}
            selectedTokens={selectedTokens}
          />
        )}
      </div>
    );
  }
}

export default StoreIntegrations;
