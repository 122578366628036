import React from "react";
import { CardType } from "../../Types";

interface Props {
  cardType: CardType;
  onClickCardType: (e: any, cardType: CardType) => void;
  handleTooltipClick: (title: string) => void;
}
const CardDetail: React.FC<Props> = ({ cardType, onClickCardType, handleTooltipClick }) => (
  <div className="card-other-details">
    <button
      className="select-button mb-3"
      onClick={(e) => onClickCardType(e, cardType)}
    >
      Get Started <i className="fa fa-long-arrow-right"></i>
    </button>
    <ul>
      <li>
        <i
          className="fa fa-info-circle"
          aria-hidden="true"
          title="Account load fee is incurred in the respective currency for each load done to the prefunding account of the client card."
          onClick={(e) =>
            handleTooltipClick(e.currentTarget.getAttribute("title") || "")
          }
        ></i>
        <span>
          Loading Fee:{" "}
          <span className="card-details-data">{cardType.loading_fee}%</span>
        </span>
      </li>
      <li>
        <i
          className="fa fa-info-circle"
          aria-hidden="true"
          title="Conversion of crypto to fiat rates."
          onClick={(e) =>
            handleTooltipClick(e.currentTarget.getAttribute("title") || "")
          }
        ></i>
        <span>
          Conversion Fee:{" "}
          <span className="card-details-data">{cardType.conversion_fee}%</span>
        </span>
      </li>
      <li>
        <i
          className="fa fa-info-circle"
          aria-hidden="true"
          title="Fee charged to replace card."
          onClick={(e) =>
            handleTooltipClick(e.currentTarget.getAttribute("title") || "")
          }
        ></i>
        <span>
          {" "}
          Lost Card Replacement Fee:{" "}
          <span className="card-details-data">${cardType.replacement_fee}</span>
        </span>
      </li>
      <li>
        <i
          className="fa fa-info-circle"
          aria-hidden="true"
          title="Fee charged annually for card maintenance."
          onClick={(e) =>
            handleTooltipClick(e.currentTarget.getAttribute("title") || "")
          }
        ></i>
        <span>
          {" "}
          Annual Maintenance Fee:{" "}
          <span className="card-details-data">
            ${cardType.yearly_maintenance_fee}
          </span>
        </span>
      </li>
      <li>
        <i
          className="fa fa-info-circle"
          aria-hidden="true"
          title="Fee charged by ATMs upon withdrawal."
          onClick={(e) =>
            handleTooltipClick(e.currentTarget.getAttribute("title") || "")
          }
        ></i>
        <span>
          ATM Withdrawal Fee:{" "}
          <span className="card-details-data">
            {cardType.atm_withdrawal_fee}%
          </span>
        </span>
      </li>
      <li>
        <i
          className="fa fa-info-circle"
          aria-hidden="true"
          title="Fee charged by ATMs to check balance."
          onClick={(e) =>
            handleTooltipClick(e.currentTarget.getAttribute("title") || "")
          }
        ></i>
        <span>
          ATM Check Balance Fee:{" "}
          <span className="card-details-data">
            {cardType.check_balance_fee_atm}
          </span>
        </span>
      </li>
      <li>
        <i
          className="fa fa-info-circle"
          aria-hidden="true"
          title="Fee charged for use of cards at with a POS as well as various online platforms."
          onClick={(e) =>
            handleTooltipClick(e.currentTarget.getAttribute("title") || "")
          }
        ></i>
        <span>
          {" "}
          Pos & Online Fee:{" "}
          <span className="card-details-data">{cardType.pos_online_fee}%</span>
        </span>{" "}
      </li>
      <li>
        <i
          className="fa fa-info-circle"
          aria-hidden="true"
          title="Maximum amount card can be credited monthly."
          onClick={(e) =>
            handleTooltipClick(e.currentTarget.getAttribute("title") || "")
          }
        ></i>
        <span>
          {" "}
          <strong>Monthly Spend Cap: </strong>
          <span className="card-details-data">
            {cardType.loading_cap_monthly}
          </span>
        </span>{" "}
      </li>
    </ul>
  </div>
);

export default CardDetail;
